<p-toast></p-toast>

<div class="layout-topbar">
  <div class="topbar-left">
    <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
      <i class="pi pi-chevron-left"></i>
    </a>
    <span class="topbar-separator"></span>
<!--    <ng-template ngFor let-item let-last="last" [ngForOf]="items">
      <span class="viewname" style="text-transform: uppercase">{{item.label}}</span>
    </ng-template>-->
    <img id="logo-mobile" class="mobile-logo"
         [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'"
         alt="diamond-layout"/>
  </div>

  <div class="topbar-right">
    <ul class="topbar-menu">
<!--      <li class="static sm:relative">
        <a tabindex="0" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
          <i class="pi pi-bell"></i>
          <span class="topbar-badge">5</span>
        </a>
        <ul class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-19rem mt-2 right-0 z-5 top-auto">
          <li>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-shopping-cart mr-3"></i>
              <span class="flex flex-column">
                                <span class="font-semibold">New Order</span>
                                <span class="text-color-secondary">You have <strong>3</strong> new orders.</span>
                            </span>
            </a>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-check-square mr-3"></i>
              <span class="flex flex-column">
                                <span class="font-semibold">Withdrawn Completed</span>
                                <span class="text-color-secondary">Funds are on their way.</span>
                            </span>
            </a>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-chart-line mr-3"></i>
              <span class="flex flex-column">
                                <span class="font-semibold">Monthly Reports</span>
                                <span class="text-color-secondary">Monthly Reports are ready.</span>
                            </span>
            </a>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-comments mr-3"></i>
              <span class="flex flex-column">
                                <span class="font-semibold">Comments</span>
                                <span class="text-color-secondary"><strong>2</strong> new comments.</span>
                            </span>
            </a>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-exclamation-circle mr-3"></i>
              <span class="flex flex-column">
                                <span class="font-semibold">Chargeback Request</span>
                                <span class="text-color-secondary"><strong>1</strong> to review.</span>
                            </span>
            </a>
          </li>
        </ul>
      </li>-->

      <li class="profile-item static sm:relative">
        <a tabindex="0" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
          <span class="profile-name">{{displayUsername}}</span>
        </a>
        <ul class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-12rem mt-2 right-0  z-5 top-auto">
          <li>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-user mr-3"></i>
              <span class="flex flex-column">
                  <span class="font-semibold" translate>TOP_MENU.PROFILE_MENU.PROFILE</span>
              </span>
            </a>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-cog mr-3"></i>
              <span class="flex flex-column">
                  <span class="font-semibold" translate>TOP_MENU.PROFILE_MENU.SETTINGS</span>
              </span>
            </a>
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
               (click)="logout()">
              <i class="pi pi-power-off mr-3"></i>
              <span class="flex flex-column">
                  <span class="font-semibold" translate>TOP_MENU.PROFILE_MENU.LOGOUT</span>
              </span>
            </a>
          </li>
        </ul>
      </li>

      <li class="language-item static sm:relative">
        <a tabindex="0" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
          <span *ngIf="language$|async as language">
            <img src="assets/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + language.flag" width="50">
          </span>
        </a>
        <ul class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-12rem mt-2 right-0  z-5 top-auto">
          <li *ngFor="let language of languages$|async">
            <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
               (click)="selectLanguage(language)" href="#">
              <img src="assets/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + language.flag" width="30">
              <span class="flex flex-column">
                  <span class="image-text ml-1">{{language.name}}</span>
              </span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
