import { Component, OnDestroy, OnInit } from '@angular/core';
import { Offer } from '../models/offer.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {OrdersFilter, OrdersService} from '../service/orders.service';
import { OrderFullModalComponent } from '../core/modals/order-full-modal/order-full-modal.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Offer[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Offer[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  filter: OrdersFilter = new OrdersFilter(0, 10, '-dateCreated');

  orders: Offer[];
  selectedOrder: Offer;

  ref: DynamicDialogRef;

  loading = true;

  constructor(
    private ordersService: OrdersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    // Check if you come from home -> create = 1 then open modal for create new offer
    if (this.route.snapshot.queryParamMap.get('buyer')) {
      this.openModal(this.route.snapshot.queryParamMap.get('buyer'), null);
    } else if (this.route.snapshot.queryParamMap.get('create') === '1') {
      this.openModal(null, null);
    }
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  fetch(): void {
    this.loading = true;
    this.ordersService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.orders = data.values;
        this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  viewCustomer(id): void {
    this.router.navigate(['customer/' + id]);
  }

  viewOrder(id): void {
    this.router.navigate(['order/' + id]);
  }

  openModal(buyer: string, input: Offer): void {
    this.ref = this.dialogService.open(OrderFullModalComponent, {
      header: this.translate.instant('ORDER.ORDER'),
      width: '98vw',
      height: '96vh',
      data: {
        buyer,
        offer: input
      }
    });

    this.ref.onClose.subscribe((offer: Offer) => {
      if (offer) {
        this.fetch();
      }
    });
  }

  delete(order: Offer): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete offer ' + order.number + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.ordersService.delete(order.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted offer: ' + order.number,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }
}
