import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '../models/project.model';
import { ProjectsFilter, ProjectsService } from '../service/projects.service';
import { map } from 'rxjs/operators';
import { ProjectFullModalComponent } from '../core/modals/project-full-modal/project-full-modal.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Project[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  projects: Project[];
  selectedProjects: Project[];

  ref: DynamicDialogRef;

  loading = true;

  filter: ProjectsFilter = new ProjectsFilter(0, 10, 'name');

  constructor(
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.filter = {...this.filter, ...JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')};
  }

  ngOnDestroy(): void {
    this.items.complete();
    this.count.complete();
    this.pending.complete();
  }

  fetch(): void {
    this.loading = true;
    this.pending.next(true);
    this.projectsService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.projects = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  openModal(input: Project): void {
    this.ref = this.dialogService.open(ProjectFullModalComponent, {
      header: this.translate.instant('PROJECTS.PROJECT'),
      width: '70vw',
      data: {project: input}
    });

    this.ref.onClose.subscribe((project: Project) => {
      if (project) {
        this.fetch();
      }
    });
  }

  delete(project: Project): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + project.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.projectsService.delete(project.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted project: ' + project.name,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  viewProject(id): void {
    this.router.navigate(['project/' + id]);
  }

}
