<div class="card py-4 px-3 md:px-6 overflow-auto">
  <div class="flex flex-column align-items-start md:flex-row md:align-items-center md:justify-content-between border-bottom-1 surface-border pb-5 min-w-max">
    <div class="flex flex-column">
      <img id="invoice-logo" [src]="'assets/images/Logo/chemcolor-logo.png'" class="mb-2" alt="chemcolor"
           height="50px"
           width="208px"/>
      <div *ngIf="this.order.seller?.address !== null">{{this.order.seller.address.street1}}</div>
      <div
        *ngIf="this.order.seller?.address !== null">{{this.order.seller.address.postalCode}} {{this.order.seller.address.postal}}
        , {{this.order.seller.address.country.name}}</div>
      <div>VAT: {{this.order.seller.vat}}</div>
    </div>
    <div class="flex flex-column mt-5 md:mt-0">
      <div class="text-xl font-semibold text-left md:text-right mb-2 text-uppercase" translate>
        ORDER.ORDER<span>: {{this.order.number}}</span></div>
      <div class="flex flex-column">
        <div class="flex justify-content-between align-items-center mb-1">
          <span class="font-semibold mr-6" translate>OFFER.DATE</span>
          <span>{{this.order.dateCreated|date:'dd MMM yyyy'}}</span>
        </div>
        <div class="flex justify-content-between align-items-center mb-1">
          <span class="font-semibold mr-6" translate>OFFER.VALID_UNTIL</span>
          <span>{{this.order.dateValid|date:'dd MMM yyyy'}}</span>
        </div>
        <div class="flex justify-content-between align-items-center mb-1">
          <span class="font-semibold mr-6" translate>OFFER.DELIVERY</span>
          <span translate>{{this.order.dateDelivery | date}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5 mb-8 flex flex-column">
    <div class="mb-2 text-2xl font-medium">{{this.order.buyer?.name}}</div>
    <div *ngIf="this.order.buyer?.address !== null">{{this.order.buyer?.address?.street1}}</div>
    <div
      *ngIf="this.order.buyer?.address !== null">{{this.order.buyer?.address?.postalCode}} {{this.order.buyer?.address?.postal}}
      , {{this.order.buyer?.address?.country.name}}</div>
    <div>VAT: {{this.order.buyer?.vat}}</div>
  </div>

  <div class="invoice-items" [ngStyle]="{'min-height': showDiscounts ? '170px' : '255px'}">
    <p-table [value]="this.order.ordersArticles" dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 48%" translate>OFFER.NAME</th>
          <th style="width:4rem; text-align: right" translate>OFFER.EAN</th>
          <th style="width:4rem; text-align: right" translate>OFFER.QUANTITY</th>
          <th style="width:4rem; text-align: right" translate>OFFER.PRICE_PER_PIECE</th>
          <th *ngIf="showDiscounts" style="width:4rem; text-align: right" translate>OFFER.ARTICLE_DISCOUNT</th>
          <th *ngIf="showVAT" style="width:4rem; text-align: right" translate>OFFER.ARTICLE_VAT<span> %</span></th>
          <th *ngIf="!showDiscounts" style="width:4rem; text-align: right" translate>OFFER.PRICE</th>
          <th *ngIf="showDiscounts" style="width:4rem; text-align: right" translate>
            OFFER.DISCOUNTED_ARTICLE_PRICE
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-orderArticle>
        <tr>
          <td>{{orderArticle.article.nameLong}}</td>
          <td style="text-align: right">{{orderArticle.article.ean}}</td>
          <td style="text-align: right">{{orderArticle.quantity}}</td>
          <td *ngIf="showDiscounts"
              style="text-align: right">{{orderArticle.priceNet|currency: 'EUR':'symbol'|empty}}</td>
          <td *ngIf="!showDiscounts"
              style="text-align: right">{{pricesService.articleSinglePriceWithoutDiscount(orderArticle)|currency: 'EUR':'symbol'|empty}} </td>
          <td *ngIf="showDiscounts" style="text-align: right">{{orderArticle.discount}} %</td>
          <td *ngIf="showVAT" style="text-align: right">{{orderArticle.vat1}} %</td>
          <td
            style="text-align: right">{{pricesService.articlePriceTotal(orderArticle)|currency: 'EUR':'symbol'|empty}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mt-8">
    <div class="font-semibold mb-3 md:mb-0">
      <b translate>OFFER.NOTES</b>
      <div>{{order.remarks}}</div>
      <br>
      <b translate>OFFER.RECEIVER</b>
      <div class="bill-to">{{this.order.receiver?.name}}</div>
      <div class="invoice-to-info">
        <div *ngIf="this.order.receiver?.address !== null">{{this.order.receiver?.address?.street1}}</div>
        <div *ngIf="this.order.receiver?.address !== null">{{this.order.receiver?.address?.postalCode}} {{this.order.receiver?.address?.postal}}
          , {{this.order.receiver?.address?.country?.name}}</div>
        <div>VAT: {{this.order.receiver.vat}}</div>
      </div>
    </div>
    <div class="flex flex-column text-l font-bold">
      <div *ngIf="showDiscounts">
        <div class="flex justify-content-between align-items-center mb-2">
          <div class="mr-3 invoice-label text-uppercase" translate>OFFER.SUBTOTAL</div>
          <div class="invoice-value">{{pricesService.offerPriceWithoutDiscount(order)|currency: 'EUR':'symbol'|empty}}</div>
        </div>
        <div class="flex justify-content-between align-items-center mb-2">
          <div class="mr-3 invoice-label text-uppercase" translate>OFFER.ARTICLES_DISCOUNT</div>
          <div class="invoice-value">{{(order.priceTotal - order.priceTotalDiscount)|currency: 'EUR':'symbol'|empty}}</div>
        </div>
        <div class="flex justify-content-between align-items-center mb-2">
          <div class="mr-3 invoice-label text-uppercase" translate>OFFER.ORDER_DISCOUNT</div>
          <div class="invoice-value">{{pricesService.offerPriceDiscount(order)|currency: 'EUR':'symbol'|empty}}</div>
        </div>
        <div class="flex justify-content-between align-items-center mb-2">
          <div class="mr-3 invoice-label text-uppercase" translate>OFFER.DISCOUNTED_PRICE</div>
          <div class="invoice-value">{{pricesService.offerPriceDiscountedPrice(order)|currency: 'EUR':'symbol'|empty}}</div>
        </div>
      </div>
      <div class="flex justify-content-between align-items-center mb-2">
        <div *ngIf="showVAT" class="mr-3 invoice-label text-uppercase" translate>OFFER.VAT</div>
        <div *ngIf="showVAT" class="invoice-value">{{pricesService.offerPriceVat(order)|currency: 'EUR':'symbol'|empty}}</div>
      </div>
      <div class="flex justify-content-between align-items-center mb-2">
        <div class="mr-3 invoice-label text-uppercase" translate>OFFER.TOTAL</div>
        <div class="invoice-value">{{pricesService.offerPriceFull(order)|currency: 'EUR':'symbol'|empty}}</div>
      </div>
    </div>
  </div>
  <div class="mt-8">
    <small>Družba Chemcolor Sevnica d.o.o. ima z družbo Slopak d.o.o. sklenjeno pogodbo št. 300/26-17
      skladno
      s 26. in 34. členom Uredbe o ravnanju z embalažo in odpadno embalažo. Za zamudo pri plačilu si
      pridržujemo pravico zaračuna pogodbene oziroma zakonite zamudne obres. Pridržujemo si lastninsko
      pravico
      za prodano blago do poravnave celotne vrednos terjatev. V primeru predčasnega plačila priznavamo
      sconto
      0,06 dnevno, pod pogojem, da so poravnane vse zapadle obveznos. Reklamacije upoštevamo najkasneje 8
      dni
      po prejemu blaga. V primeru spora je pristojno Okrožno sodišče v Krškem.</small>
  </div>
  <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mt-1" style="text-align: justify;">
    <div class="">
      <img src="assets/images/SIQ.png" alt="diamond-layout" width="80px" class="siq">
    </div>
    <div>
      <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between">
        <div>
          <small><b>Banka Intesa Sanpaolo d.d.<br/>
            IBAN: SI56 1010 0005 5221 222 SWIFT: BAKOSI2X</b></small>
        </div>
        <div>
          <small><b>Nova Ljubljanska Banka d.d.<br/>
            IBAN: SI56 0237 9001 3890 812 SWIFT: LJBASI2X</b></small>
        </div>

      </div>
      <div>
        <small>ID za DDV: SI24218359 • Matična številka: 5309115 • Registrirano pri Okrožnem sodišču v
          Novem
          mestu •
          Številka vložka: 1/392/00 • Osnovni kapital: 106.826,90 EUR</small>
      </div>
    </div>
  </div>
</div>

