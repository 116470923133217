import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CodelistsService } from '../../../service/codelists.service';
import { Product } from '../../../models/product.model';
import { ProductsService } from '../../../service/products.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Codelist } from '../../../models/codelist.model';
import {
  CodelistColorccFullModalComponent
} from '../codelist-colorcc-full-modal/codelist-colorcc-full-modal.component';

@Component({
  selector: 'app-product-full-modal',
  templateUrl: './product-full-modal.component.html',
  styleUrls: ['./product-full-modal.component.scss']
})
export class ProductFullModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup;

  filtered: any;

  @Input() product: Product;

  constructor(
    private productsService: ProductsService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      name: fb.control(null, Validators.required),
      pantheon: fb.control(null),
      code: fb.control(null),
      description: fb.control(null),
      density: fb.control(null),
      weight: fb.control(null),
      length: fb.control(null),
      width: fb.control(null),
      height: fb.control(null),
      colorCc: fb.control(null),
      colorNcs: fb.control(null),
      colorRal: fb.control(null),
      gloss: fb.control(null),
      type: fb.control(null),
      recipe: fb.control(null),
      category: fb.control(null),
      unit: fb.control(null),
      accountingClassification: fb.control(null)
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.product = this.config.data.product;
    }
    if (this.product && this.product.id) {
      this.form.patchValue(this.product);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const product = {...this.product, ...this.form.getRawValue()};

    if (product.id) {
      this.productsService.update(product.id, product)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated product: ' + product.name,
            });
            this.ref.close(product);
          },
          error => {
            console.log(error);
          });
    } else {
      this.productsService.create(product)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created product: ' + product.name,
            });
            this.ref.close(product);
          },
          error => {
            console.log(error);
          });
    }
  }

  addNewColorModal(input: Codelist): void {
    this.ref = this.dialogService.open(CodelistColorccFullModalComponent, {
      header: this.translate.instant('CODELIST.CODELIST_COLOR_CC'),
      width: '40vw',
      data: {codelist: input}
    });

    this.ref.onClose.subscribe((codelist: Codelist) => {
      if (codelist) {}
    });
  }
}
