import { Component } from '@angular/core';
import { FeatureCollection, FloorPlanService } from '../service/floor-plan.service';

@Component({
  selector: 'app-floor-plan',
  templateUrl: './floor-plan.component.html',
  styleUrls: ['./floor-plan.component.scss']
})
export class FloorPlanComponent {

  projection: any;

  roomsData: FeatureCollection;

  buildingData: FeatureCollection;

  constructor(service: FloorPlanService) {
    this.roomsData = service.getRoomsData();
    this.buildingData = service.getBuildingData();
    this.projection = {
      to(coordinates) {
        return [coordinates[0] / 100, coordinates[1] / 100];
      },
      from(coordinates) {
        return [coordinates[0] * 100, coordinates[1] * 100];
      },
    };
  }

  customizeTooltip(arg) {
    if (arg.layer.name === 'rooms') {
      return {
        text: `Senzor: ${arg.attribute('square')}`,
      };
    }
  }
}
