<div class="grid" *ngIf="product$|async as product">
  <div class="col-8">
    <h3>{{product.name|empty}}</h3>
  </div>
  <div class="col-4 flex justify-content-end mb-2">
    <p-button type="button" class="p-button-secondary" icon="pi pi-tablet"
              *ngIf="this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')"
              [label]="'PRODUCT.EDIT.TITLE' | translate"
              (click)="openProductModal(product)"
    ></p-button>
  </div>

  <!--    region PRODUCT TABS  -->
  <div class="col-12 md:col-12 p-xl-8">
    <div class="card">
      <p-tabView orientation="left">
        <p-tabPanel header="{{'CONTACT.DETAILS' | translate}}" leftIcon="pi pi-user">
          <div class="p-fluid formgrid grid">
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PRODUCTS.NAME</p></div>
            <div class="col-12 md:col-6"><p>{{ product.name | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PRODUCTS.COLOR_CC</p></div>
            <div class="col-12 md:col-6"><p>{{ product.colorCc?.name | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PRODUCTS.COLOR_RAL</p></div>
            <div class="col-12 md:col-6"><p>{{ product.colorRal?.code | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PRODUCTS.COLOR_NCS</p></div>
            <div class="col-12 md:col-6"><p>{{ product.colorNcs?.code | empty }}</p></div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{'ARTICLES.ARTICLES' | translate}}" leftIcon="pi pi-user">
          <p-table #dt
                   [value]="articles"
                   [lazy]="true"
                   (onLazyLoad)="pageChanged($event)"
                   dataKey="articleId"
                   [rowHover]="true"
                   [rows]="articlesFilter.limit"
                   [totalRecords]="(countArticles$ | async) || 0"
                   [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,15,20,50,200]"
                   [loading]="loading"
                   [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0"
                   [globalFilterFields]="['nameLong']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
              <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                        (click)="openArticleModal(null)"
                        *ngIf="this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')"
                        [label]="'ARTICLES.CREATE_ARTICLE' | translate"></p-button>
            </span>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="nameLong">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    ARTICLES.NAME
                    <p-sortIcon field="nameLong"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="ean">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    ARTICLE.EAN
                    <p-sortIcon field="ean"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="ident">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    ARTICLE.IDENT
                    <p-sortIcon field="ident"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="gloss">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    PRODUCTS.GLOSS
                    <p-sortIcon field="gloss"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="priceNet">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    ARTICLE.PRICE_COST
                    <p-sortIcon field="priceNet"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="priceGross">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    ARTICLE.PRICE_RETAIL
                    <p-sortIcon field="priceGross"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="priceTotal">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    ARTICLE.PRICE_WHOLESOME
                    <p-sortIcon field="priceTotal"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-article>
              <tr class="p-row">
                <td>
                  {{article.nameLong}}
                </td>
                <td>
                  {{article.ean}}
                </td>
                <td>
                  {{article.ident}}
                </td>
                <td>
                  {{article.gloss?.name}}
                </td>
                <td>
                  {{article.priceNet}}
                </td>
                <td>
                  {{article.priceGross}}
                </td>
                <td>
                  {{article.priceTotal}}
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                          *ngIf="this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')"
                          (click)="openArticleModal(article)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          *ngIf="this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')"
                          (click)="deleteArticle(article)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>ARTICLES.NO_ARTICLES_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.ATTACHMENTS' | translate}}" leftIcon="pi pi-upload">
          <p-table #dt [value]="uploadedFiles" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['attachment.name', 'attachment.size']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button type="button" class="p-button-secondary pr-2" icon="pi pi-upload"
                            [label]="'ATTACHMENTS.ADD' | translate"
                            (click)="openModalUpload(id, 'PRODUCTS')"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileName">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="fileName"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileSize">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.SIZE
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-attachment>
              <tr class="p-row">
                <td>
                  {{attachment.name}}
                </td>
                <td>
                  <i [ngClass]="attachmentsService.getFileContentType(attachment.fileContentType)" class="mr-3 pi text-primary text-xl"></i>
                  {{attachment.fileName}}
                </td>
                <td>
                  {{Math.floor(attachment.fileSize / 1024)}} kB
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-download"
                          (click)="downloadAttachment(attachment)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteAttachment(attachment)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
              </tr>
            </ng-template>
          </p-table>

        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <!--    endregion PRODUCT TABS    -->

  <!--    region  ADD ORDER / OFFER BUTTONS  -->
  <div *ngIf="this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')" class="col-12 md:col-12 p-xl-4">
    <div class="grid">
      <div class="col-12 md:col-12 p-lg-6">
        <div class="card widget-feature-box" (click)="openArticleModal(null)">
          <i class="pi pi-plus mr-2" style="font-size: 20px"></i>
          <i class="pi pi-fw pi-id-card" style="font-size: 40px"></i>
          <h2 translate>BUTTON.ARTICLES</h2>
          <span translate>BUTTON.ADD_NEW_ARTICLE</span>
        </div>
      </div>
      <!--<div class="col-12 md:col-12 p-lg-6">
        <div class="card widget-feature-box" (click)="addOrder()">
          <i class="pi pi-plus mr-2" style="font-size: 20px"></i>
          <i class="pi pi-fw pi-check-square" style="font-size: 40px"></i>
          <h2 translate>BUTTON.ORDERS</h2>
          <span translate>BUTTON.ADD_NEW_ORDER</span>
        </div>
      </div>-->
    </div>
  </div>
  <!--    endregion  ADD ORDER / OFFER BUTTONS    -->
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
