import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(
    private http: HttpClient
  ) { }

  findById(entityId: any, entityType: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/attachments/${entityType}/${entityId}`);
  }

  downloadAttachment(attachmentId: any): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/attachments/${attachmentId}`, {
      responseType: 'blob',
    });
  }

  delete(attachmentId: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/attachments/${attachmentId}`);
  }

  public getFileContentType(contentType: any): any {
    let icon = 'pi-file';
    if (contentType.includes('image')) {
      icon = 'pi-image';
    } else if (contentType.includes('excel') || contentType.includes('sheet')) {
      icon = 'pi-file-excel';
    } /*else if (contentType.includes('word')) {
      icon = 'pi-file-word';
    }*/ else if (contentType.includes('pdf')) {
      icon = 'pi-file-pdf';
    }
    return icon;
  }

  uploadAttachment(file: File, fileName: string, entityId: string, entityType: string): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('file', file);
    if (fileName != null) {
      formData.append('fileName', fileName);
    }

    // Customize headers if needed (e.g., for authentication)
    const headers = new HttpHeaders();
    return this.http.post(`${environment.apiUrl}/attachments/${entityType}/${entityId}`, formData, { headers });
  }
}
