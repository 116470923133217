import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {NotesService} from '../../../service/notes.service';
import {Note} from '../../../models/note.model';

@Component({
  selector: 'app-note-full-modal',
  templateUrl: './note-full-modal.component.html',
  styleUrls: ['./note-full-modal.component.scss']
})
export class NoteFullModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  filtered: any;
  @Input() note: Note;
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private notesService: NotesService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      title: fb.control(null, Validators.required),
      text: fb.control(null),
      customerId: fb.control(null),
      projectId: fb.control(null),
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.note = this.config.data.note;
    }
    if (this.note && this.note.id) {
      this.form.patchValue(this.note);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const note = {...this.note, ...this.form.getRawValue()};
    if (this.note.customer.id != null) {
      note.customer.id = this.note.customer.id;
    }
    if (note.id) {
      this.notesService.update(note.id, note)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('SUCCESS.SUCCESS'),
              detail: this.translate.instant('SUCCESS.UPDATED.NOTE') + ' ' + note.title,
            });
            this.ref.close(note);
          },
          error => {
            console.log(error);
          });
    } else {
      this.notesService.create(note)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('SUCCESS.SUCCESS'),
              detail: this.translate.instant('SUCCESS.CREATED.NOTE') + ' ' + note.title,
            });
            this.ref.close(note);
          },
          error => {
            console.log(error);
          });
    }
  }

}
