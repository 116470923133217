import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customer } from '../models/customer.model';
import { BaseApi, ListData, StandardFilter } from '../core/api/base-api';

export class CustomersFilter extends StandardFilter {
  search?: string;
}

@Injectable()
export class CustomersService extends BaseApi  {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAll(): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${environment.apiUrl}/customers`);
  }

  find(filter: CustomersFilter): Observable<ListData<Customer>> {
    return this.list<Customer>(`${environment.apiUrl}/customers`, { params: this.params(filter) });
  }

  get(id: any): Observable<Customer> {
    return this.http.get(`${environment.apiUrl}/customers/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/customers`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/customers/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/customers/${id}`);
  }

  deleteAll(): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/customers`);
  }

  findByName(name: any): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${environment.apiUrl}/customers?name=${name}`);
  }

  // Customer types
  saveRankings(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/customers/${id}/rankings`, data);
  }

  getRankings(id: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/customers/${id}/rankings`);
  }
}
