import { Injectable } from '@angular/core';
import { Offer, OfferArticle } from '../models/offer.model';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

@Injectable()
export class PricesService {

  constructor() {
  }

  round(value, decimals) {
    return Number(Math.round(Number(value + 'e' + decimals) ) + 'e-' + decimals);
  }

  articlePriceTotal(offerArticle: OfferArticle) {
    return this.round((offerArticle.priceNet * offerArticle.quantity * ((100 - offerArticle.discount) / 100)), 2);
  }

  articleSinglePriceWithoutDiscount(offerArticle: OfferArticle) {
    return this.round((offerArticle.priceNet * ((100 - offerArticle.discount) / 100)), 2);
  }

  articleSingleDiscountPrice(offerArticle: OfferArticle) {
    return this.round((offerArticle.priceNet * ((offerArticle.discount) / 100)), 2);
  }

  offerPrice(offer: Offer) {
    let offerPrice = 0;
    if (isNotNullOrUndefined(offer.offersArticles)) {
      offer.offersArticles.forEach(offerArticle => {
        offerPrice += this.articlePriceTotal(offerArticle);
      });
    } else if (isNotNullOrUndefined(offer.ordersArticles)) {
      offer.ordersArticles.forEach(offerArticle => {
        offerPrice += this.articlePriceTotal(offerArticle);
      });
    }

    return this.round(offerPrice, 2);
  }

  offerPriceWithoutDiscount(offer: Offer) {
    let offerPrice = 0;
    if (isNotNullOrUndefined(offer.offersArticles)) {
      offer.offersArticles.forEach(offerArticle => {
        offerPrice += (offerArticle.priceNet * offerArticle.quantity);
      });
    } else if (isNotNullOrUndefined(offer.ordersArticles)) {
      offer.ordersArticles.forEach(orderArticle => {
        offerPrice += (orderArticle.priceNet * orderArticle.quantity);
      });
    }
    return this.round(offerPrice, 2);
  }

  offerPriceFull(offer: Offer) {
    return this.offerPriceVat(offer) + this.offerPriceDiscountedPrice(offer);
  }

  offerPriceVat(offer: Offer) {
    if (!offer.buyer) {
      return 0;
    }
    return this.round((this.offerPrice(offer) * (100 - offer.discount) / 100) *
      (parseFloat((offer.buyer.address && offer.buyer.address.country) ?
        offer.buyer.address.country.vat1 : offer.seller.address.country.vat1)) / 100, 2);
  }

  offerPriceDiscount(offer: Offer) {
    return this.round(this.offerPrice(offer) * offer.discount / 100, 2);
  }

  offerPriceDiscountedPrice(offer: Offer) {
    return this.round(this.offerPrice(offer) * (100 - offer.discount) / 100, 2);
  }

  articlesPriceDiscount(offer: Offer) {
    let articlesDiscountPrice = 0;
    if (isNotNullOrUndefined(offer.offersArticles)) {
      offer.offersArticles.forEach(offerArticle => {
        articlesDiscountPrice += this.articleSingleDiscountPrice(offerArticle);
      });
    } else if (isNotNullOrUndefined(offer.ordersArticles)) {
      offer.ordersArticles.forEach(orderArticle => {
        articlesDiscountPrice += this.articleSingleDiscountPrice(orderArticle);
      });
    }
    return this.round(articlesDiscountPrice, 2);
  }

  calculateArticlePricePerUnit(offerArticle: OfferArticle) {
    if (offerArticle.priceNet != null && offerArticle.article.packing?.volume != null) {
      offerArticle.pricePerUnit = offerArticle.priceNet / offerArticle.article.packing.volume;
    }
    return offerArticle.pricePerUnit;
  }

  calculateArticlePriceNet(offerArticle: OfferArticle) {
    if (offerArticle.pricePerUnit != null && offerArticle.article.packing?.volume != null) {
      offerArticle.priceNet = offerArticle.pricePerUnit * offerArticle.article.packing.volume;
    }
    return offerArticle.priceNet;
  }
}
