import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { Offer } from '../../models/offer.model';
import { PricesService } from '../../service/prices.service';
import {DeliveryNote} from '../../models/deliveryNote.model';

@Component({
  selector: 'app-delivery-note-view',
  templateUrl: './delivery-note-view.component.html',
  styleUrls: ['./delivery-note-view.component.scss']
})
export class DeliveryNoteViewComponent implements OnInit {

  @Input() deliveryNote: DeliveryNote;
  @Input() showDiscounts = false;
  @Input() showVAT = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public pricesService: PricesService
  ) {
    this.breadcrumbService.setItems([
      {label: 'Delivery Note'}
    ]);
  }

  print() {
    window.print();
  }

  ngOnInit(): void {
    // this.offer.dateCreated = new Date(this.offer.dateCreated + 'Z');
  }
}
