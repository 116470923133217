import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Project } from '../../models/project.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '../../service/projects.service';
import { ProjectFullModalComponent } from '../../core/modals/project-full-modal/project-full-modal.component';
import { Offer } from '../../models/offer.model';
import { OffersService } from '../../service/offers.service';
import { OfferFullModalComponent } from '../../core/modals/offer-full-modal/offer-full-modal.component';
import {Form} from '../../models/form.model';
import {ChecklistFullModalComponent} from '../../core/modals/checklist-full-modal/checklist-full-modal.component';
import {FormsService} from '../../service/forms.service';
import {AttachmentFullModalComponent} from '../../core/modals/attachment-full-modal/attachment-full-modal.component';
import {AttachmentsService} from '../../service/attachments.service';
import {SharedAttachmentService} from '../../service/sharedAttachments.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  id: number;
  loading = true;
  forms: Form[];

  // Attachments
  uploadedFiles: any[] = [];

  ref: DynamicDialogRef;

  private project: BehaviorSubject<Project> = new BehaviorSubject(null);
  project$: Observable<Project> = this.project.asObservable();

  offers: Offer[];

  protected readonly Math = Math;

  constructor(
    private projectsService: ProjectsService,
    private offersService: OffersService,
    private formsService: FormsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private translate: TranslateService,
    private attachmentsService: AttachmentsService,
    private sharedAttachmentService: SharedAttachmentService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.fetch(this.id);
    this.fetchOffers();
    this.fetchForms();
    this.fetchAttachments();
  }

  fetch(id: number): void {
    this.loading = true;
    this.projectsService.findById(id)
      .subscribe(
        data => {
          this.project.next(data);
        },
        error => {
          console.log(error);
        });
  }

  fetchOffers(): void {
    this.offersService.getAllForProject(this.id)
      .subscribe(
        data => {
          this.offers = data;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  fetchForms(): void {
    this.formsService.find().subscribe(data => {
      if (data) {
        this.forms = data;
        this.loading = false;
      }
    });
  }

  openProjectModal(input: Project): void {
    this.ref = this.dialogService.open(ProjectFullModalComponent, {
      header: this.translate.instant('PROJECT.PROJECT'),
      width: '70vw',
      data: {project: input}
    });

    this.ref.onClose.subscribe((project: Project) => {
      if (project) {
        this.fetch(project.id);
      }
    });
  }

  openOfferModal(project: string, input: Offer): void {
    this.ref = this.dialogService.open(OfferFullModalComponent, {
      header: this.translate.instant('OFFER.OFFER'),
      width: '98vw',
      height: '96vh',
      styleClass: 'grey-background-modal',
      data: {
        project,
        offer: input
      }
    });

    this.ref.onClose.subscribe((offer: Offer) => {
      if (offer) {
        this.fetchOffers();
      }
    });
  }

  viewOffer(id): void {
    this.router.navigate(['offer/' + id]);
  }

  deleteOffer(offer: Offer): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete offer ' + offer.number + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.offersService.delete(offer.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted offer: ' + offer.number,
              });
              this.fetchOffers();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  openChecklistModal(form: Form): void {
    this.ref = this.dialogService.open(ChecklistFullModalComponent, {
      header: this.translate.instant('MENU.CHECKLISTS'),
      width: '60vw',
      data: {
        project: this.project,
        inputForm: form
      }
    });

    this.ref.onClose.subscribe((inputForm: Form) => {
      if (inputForm) {
        this.fetchForms();
      }
    });
  }


  fetchAttachments(): void {
    this.attachmentsService.findById(this.id, 'PROJECTS')
      .subscribe(data => {
        this.uploadedFiles = data;
        this.loading = false;
      }, error => {
        console.log(error);
      });
  }

  openModalUpload(id: string, entityType: string): void {
    this.ref = this.dialogService.open(AttachmentFullModalComponent, {
      header: this.translate.instant('ATTACHMENTS.ADD'),
      width: '50vw',
      height: '25vh',
      data: {id, entityType}
    });

    this.ref.onClose.subscribe(() => {
      this.fetchAttachments();
    });
  }

  downloadAttachment(attachment: any) {
    this.sharedAttachmentService.downloadAttachment(attachment);
  }

  deleteAttachment(attachment: any) {
    this.sharedAttachmentService.deleteAttachment(attachment, this.fetchAttachments.bind(this));
  }
}
