import {Component, OnDestroy, OnInit} from '@angular/core';
import {Note} from '../models/note.model';
import {NoteFullModalComponent} from '../core/modals/note-full-modal/note-full-modal.component';
import {NotesFilter, NotesService} from '../service/notes.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '../service/auth.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Note[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  loading = true;
  ref: DynamicDialogRef;
  notes: any[];
  filter: NotesFilter = new NotesFilter(0, 10, '-modifiedDate');

  constructor(
    public dialogService: DialogService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notesService: NotesService,
    public auth: AuthService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.items.complete();
    this.count.complete();
    this.pending.complete();
  }

  fetch(): void {
    this.notesService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.notes = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  openNoteModal(note: Note): void {
    this.ref = this.dialogService.open(NoteFullModalComponent, {
      header: this.translate.instant('NOTES.NOTE'),
      width: '98vw',
      data: {
        note
      }
    });

    // tslint:disable-next-line:no-shadowed-variable
    this.ref.onClose.subscribe((note: Note) => {
      if (note) {
        this.fetch();
      }
    });
  }

  deleteNote(note: Note): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete note ' + note.title + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notesService.delete(note.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('SUCCESS.SUCCESS'),
                detail: this.translate.instant('SUCCESS.DELETED.NOTE') + ' ' + note.title,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }
}
