import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {SensorsFilter, SensorsService} from '../../service/sensors.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Sensor} from '../../models/sensor.model';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss']
})
export class SensorComponent implements OnInit {

  private items: BehaviorSubject<Sensor[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Sensor[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  filter: SensorsFilter = new SensorsFilter(0, 300);

  loading = true;

  id: string;

  lineData: any;

  humidityData: number[];
  dates: string[];
  temperatureData: number[];

  constructor(
    private translate: TranslateService,
    private sensorsService: SensorsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.fetch(this.id);
  }

  fetch(id: string): void {
    this.loading = true;
    this.sensorsService.findById(id, this.filter)
      .subscribe(data => {
          this.loading = false;
          this.items.next(data.values);
          this.temperatureData = this.items.value.map(item => {
            return item.temperature;
          });
          this.dates = this.items.value.map(item => {
            return item.timestamp;
          });
          this.humidityData = this.items.value.map(item => {
            return item.humidity;
          });
          this.lineData = {
            labels: this.dates,
            datasets: [
              {
                label: this.translate.instant('SENSORS.TEMPERATURE'),
                data: this.temperatureData,
                fill: false,
                backgroundColor: 'rgb(255, 205, 86)',
                borderColor: 'rgb(255, 205, 86)'
              },
              {
                label: this.translate.instant('SENSORS.HUMIDITY'),
                data: this.humidityData,
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgb(75, 192, 192)'
              }
            ]
          };
        },
        error => {
          console.log(error);
        });
  }

}
