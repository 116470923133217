import { Component, OnDestroy, OnInit } from '@angular/core';
import { Product } from '../models/product.model';
import { ProductsFilter, ProductsService } from '../service/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ProductFullModalComponent } from '../core/modals/product-full-modal/product-full-modal.component';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  products: Product[];
  selectedProducts: Product[];

  ref: DynamicDialogRef;

  loading = true;

  filter: ProductsFilter = new ProductsFilter(0, 10, 'name');

  constructor(
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private translate: TranslateService,
    public keycloak: KeycloakService,
  ) {
  }

  ngOnInit(): void {
    this.filter = {...this.filter, ...JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')};
  }

  ngOnDestroy(): void {
    this.items.complete();
    this.count.complete();
    this.pending.complete();
  }

  fetch(): void {
    this.loading = true;
    this.pending.next(true);
    this.productsService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.products = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  viewProduct(id): void {
    this.router.navigate(['product/' + id]);
  }

  openModal(input: Product): void {
    this.ref = this.dialogService.open(ProductFullModalComponent, {
      header: this.translate.instant('PRODUCT.PRODUCT'),
      width: '70vw',
      data: {product: input}
    });

    this.ref.onClose.subscribe((product: Product) => {
      if (product) {
        this.fetch();
      }
    });
  }

  delete(product: Product): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + product.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.productsService.delete(product.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted product: ' + product.name,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  showColorName(product: Product): string {
    if (product.colorCc && product.colorCc.name) {
      return product.colorCc.name;
    } else if (product.colorRal && product.colorRal.name) {
      return product.colorRal.name;
    } else if (product.colorNcs && product.colorNcs.name) {
      return product.colorNcs.name;
    } else {
      return '';
    }
  }

}
