import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {DeliveryNote} from '../models/deliveryNote.model';
import {BaseApi, ListData, StandardFilter} from '../core/api/base-api';

export class DeliveryNotesFilter extends StandardFilter {
  search?: string;
}

@Injectable()
export class DeliveryNotesService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAll(): Observable<DeliveryNote[]> {
    return this.http.get<DeliveryNote[]>(`${environment.apiUrl}/deliveryNotes`);
  }

  find(filter: DeliveryNotesFilter): Observable<ListData<DeliveryNote>> {
    return this.list<DeliveryNote>(`${environment.apiUrl}/deliveryNotes`, { params: this.params(filter) });
  }

  get(id: any): Observable<DeliveryNote> {
    return this.http.get<DeliveryNote>(`${environment.apiUrl}/deliveryNotes/${id}`);
  }

  getPdf(id: any, lang: any, showDiscounts: any, showVAT: any): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/deliveryNotes/${id}/pdf?lang=${lang}&showDiscounts=${showDiscounts}&showVAT=${showVAT}`,
      {responseType: 'blob'});
  }

  getAllForBuyer(buyerId: any): Observable<DeliveryNote[]> {
    return this.http.get<DeliveryNote[]>(`${environment.apiUrl}/deliveryNotes/buyer/${buyerId}`);
  }

  getAllForProject(projectId: any): Observable<DeliveryNote[]> {
    return this.http.get<DeliveryNote[]>(`${environment.apiUrl}/deliveryNotes/project/${projectId}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/deliveryNotes`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/deliveryNotes/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/deliveryNotes/${id}`);
  }
}
