<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="name" class="required-mark" translate>CODELIST.NAME</label>
      <input id="name" type="text" pInputText formControlName="name">
    </div>
    <div class="field col-12 md:col-6">
      <label for="code" translate>CODELIST.CODE</label>
      <input id="code" type="text" pInputText formControlName="code">
    </div>
    <div class="field col-12 md:col-12">
      <label for="description" translate>CODELIST.DESCRIPTION</label>
      <textarea id="description" type="text" pInputText formControlName="description"></textarea>
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" styleClass="p-button-success" (onClick)="save()"
            [disabled]="form.invalid"
            [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
