import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Article } from '../models/article.model';
import { ArticlesFilter, ArticlesService } from '../service/articles.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ArticleFullModalComponent } from '../core/modals/article-full-modal/article-full-modal.component';
import { OfferFullModalComponent } from '../core/modals/offer-full-modal/offer-full-modal.component';
import Keycloak from 'keycloak-js';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Article[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  articles: Article[];
  selectedArticles: Article[];

  ref: DynamicDialogRef;

  loading = true;

  filter: ArticlesFilter = new ArticlesFilter(0, 10, 'nameLong');

  constructor(
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private translate: TranslateService,
    public keycloak: KeycloakService
  ) {
  }

  ngOnInit(): void {
    this.filter = {...this.filter, ...JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')};
  }

  ngOnDestroy(): void {
    this.items.complete();
    this.count.complete();
    this.pending.complete();
  }

  fetch(): void {
    this.loading = true;
    this.pending.next(true);
    this.articlesService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.articles = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    // console.log(event.sortField);
    // TODO: Check for event.sortField if name make nameLong
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else if (event.sortOrder === 1) {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  openModal(input: Article): void {
    this.ref = this.dialogService.open(ArticleFullModalComponent, {
      header: this.translate.instant('ARTICLE.ARTICLE'),
      width: '70vw',
      data: {article: input}
    });

    this.ref.onClose.subscribe((article: Article) => {
      if (article) {
        this.fetch();
      }
    });
  }

  openOfferModal(input: Article[]): void {
    this.ref = this.dialogService.open(OfferFullModalComponent, {
      header: this.translate.instant('OFFER.OFFER'),
      width: '98vw',
      height: '96vh',
      data: {articles: input}
    });

    this.ref.onClose.subscribe((articles: Article[]) => {
      this.selectedArticles = [];
      this.router.navigate(['/offers']);
    });
  }

  delete(article: Article): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + article.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.articlesService.delete(article.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted article: ' + article.name,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }
}
