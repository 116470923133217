import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private keycloak: KeycloakService
  ) {
  }

  isOwner(object: any): boolean {
    return this.keycloak.getUsername().toUpperCase() === object.createdBy;
  }

  isAdmin(): boolean {
    return this.keycloak.isUserInRole('ROLE_ADMINISTRATOR');
  }

  isUser(): boolean {
    return this.keycloak.isUserInRole('ROLE_USER');
  }
}
