import {Auditable} from '../core/api/common/auditable';

export class Customer {
  id?: any;
  customerType?: any;
  registrationNumber?: number;
  name?: string;
  nameLong?: string;
  url?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  vat?: string;
  company?: boolean;
  contacts?: [Contact];
  address?: Address;
  deliveryAddress?: Address;
}

export class Contact {
  id?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  mobile?: string;
  email?: string;
  comment?: string;
  customer?: Customer;
  position?: string;
  contactType?: string;
}

export class Address {
  id?: any;
  city?: string;
  country?: Country;
  street1?: string;
  street2?: string;
  postalCode?: string;
  postal?: string;
  stateOrProvince?: string;
}

export class DeliveryAddress {
  id?: any;
  city?: string;
  country?: Country;
  street1?: string;
  street2?: string;
  postalCode?: string;
  postal?: string;
  stateOrProvince?: string;
}

export class Country {
  id?: any;
  name?: string;
  code2?: string;
  code3?: string;
  vat1?: string;
  vat2?: string;
}
