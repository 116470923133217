import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customer } from '../models/customer.model';

@Injectable()
export class InetisService {

  constructor(
    private http: HttpClient
    ) { }

  findByName(name: any): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${environment.apiUrl}/prs/find?search=${name}`);
  }

  findByVat(vat: any): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${environment.apiUrl}/prs/find?search=${vat}`);
  }
}
