import { Auditable } from '../core/api/common/auditable';
import {Language} from '../core/util/i18n-util.service';

export class Form extends Auditable {
  id: number;
  title: string;
  description: string;
  formsDefinitions: FormDefinition[];
}

export class FormDefinition extends Auditable {
  id: number;
  props: number;
  labels: number;
  orders: number;
  formElement: FormElement;
  customerId: number;
  projectId: number;
  text: string;
  number: number;
  date: Date;
  fullText: string;
  select: {value: any};
  multiSelect: {};

}

export class FormElement extends Auditable {
  id: number;
  name: string;
  props: Options;
  labels: Language;
  formElementType: FormElementType;
}

export class FormElementType extends Auditable {
  id: number;
  name: string;
  description: string;
  props: Options;
  labels: Language;
}

export class Options extends Auditable {
  options: Option[];
}

export class Option extends Auditable {
  value: string;
  en: string;
  sl: string;
  hr: string;
}

export enum FormElementTypeEnum {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT'
}
