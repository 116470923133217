import { Component, OnInit } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersFilter, CustomersService } from '../service/customers.service';
import { Customer } from '../models/customer.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  private items: BehaviorSubject<Customer[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Customer[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  filter: CustomersFilter = new CustomersFilter(0, 10, 'name');

  customers: Customer[];
  selectedCustomer: Customer;

  loading = true;

  // customer-full-modal
  customerDialog: boolean;
  updatedCustomer: Customer;
  customerForEdit: Customer;

  constructor(
    private customersService: CustomersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.filter = {...this.filter, ...JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')};
    // Check if you come from home -> create = 1 then open modal for create new offer
    this.route.queryParams.subscribe(params => {
      if (params.create === '1') {
        this.addCustomerModal(event);
      }
    });
  }

  fetch(): void {
    this.loading = true;
    this.pending.next(true);
    this.customersService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.customers = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  dialogChanged(customer: Customer): void {
    if (customer) {
      this.updatedCustomer = customer;
      this.fetch();
    } else {
      this.updatedCustomer = {};
    }
  }

  viewCustomer(id): void {
    this.router.navigate(['customer/' + id]);
  }

  editCustomerModal(customer: Customer, event): void {
    this.customerForEdit = {...customer};
    this.customerDialog = event;
  }

  addCustomerModal(event): void {
    this.customerForEdit = {};
    this.customerDialog = event;
  }

  delete(customer: Customer): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + customer.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.customersService.delete(customer.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted customer: ' + customer.name,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }
}
