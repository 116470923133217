<div class="grid" *ngIf="deliveryNote$|async as deliveryNote">
  <div class="col-12">
    <div>
      <p-dropdown [options]="offerLangs$|async" [(ngModel)]="selectedLang" optionLabel="name" class="mr-2">
        <ng-template  let-lang pTemplate="selectedItem">
          <div class="country-item country-item-value" *ngIf="lang">
            <img src="assets/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + lang.flag" />
            <span>  {{lang.name}}</span>
          </div>
        </ng-template>
        <ng-template let-lang pTemplate="item">
          <div class="country-item">
            <img src="assets/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + lang.flag" />
            <span>  {{lang.name}}</span>
          </div>
        </ng-template>
      </p-dropdown>
      <p-button type="button" class="p-button-secondary mr-2" icon="pi pi-file-pdf"
                [label]="'ACTIONS.SAVE' | translate"
                (click)="savePdf(deliveryNote.id)"></p-button>
      <p-button type="button" class="p-button-secondary mr-2" icon="pi pi-file-pdf"
                [label]="'ACTIONS.OPEN' | translate"
                (click)="openPdf(deliveryNote.id)"></p-button>
      <p-button type="button" class="p-button-secondary mr-2" icon="pi pi-pencil"
                [label]="'ACTIONS.EDIT' | translate"
                (click)="openModal(null, deliveryNote)"></p-button>
    </div>
  </div>
  <!--    region OFFER TABS  -->
  <div class="col-12">
    <div class="card">
      <p-tabView orientation="left">
        <p-tabPanel header="{{'CONTACT.DETAILS' | translate}}" leftIcon="pi pi-id-card">
          <div class="col-10 col-offset-1">
            <app-delivery-note-view [deliveryNote]="deliveryNote" [showDiscounts]="showDiscounts" [showVAT]="showVAT"></app-delivery-note-view>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.ATTACHMENTS' | translate}}" leftIcon="pi pi-upload">
          <p-table #dt [value]="uploadedFiles" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['attachment.name', 'attachment.size']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button type="button" class="p-button-secondary pr-2" icon="pi pi-upload"
                            [label]="'ATTACHMENTS.ADD' | translate"
                            (click)="openModalUpload(id, 'DELIVERY_NOTES')"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileName">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="fileName"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileSize">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.SIZE
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-attachment>
              <tr class="p-row">
                <td>
                  {{attachment.name}}
                </td>
                <td>
                  <i [ngClass]="attachmentsService.getFileContentType(attachment.fileContentType)" class="mr-3 pi text-primary text-xl"></i>
                  {{attachment.fileName}}
                </td>
                <td>
                  {{Math.floor(attachment.fileSize / 1024)}} kB
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-download"
                          (click)="downloadAttachment(attachment)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteAttachment(attachment)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
              </tr>
            </ng-template>
          </p-table>

        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <!--    endregion OFFER TABS    -->
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
