import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Customer } from '../../../models/customer.model';
import { CustomersService } from '../../../service/customers.service';
import { InetisService } from '../../../service/inetis.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CodelistsService } from '../../../service/codelists.service';
import { emailValidator } from '../../forms/validators/email-validator';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bussines-unit-full-modal',
  templateUrl: './bussines-unit-full-modal.component.html',
  styleUrls: ['./bussines-unit-full-modal.component.scss']
})
export class BussinesUnitFullModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  filtered: any;
  @Input() bussinesUnit: Customer;
  @Input() customer: Customer;
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private customersService: CustomersService,
    private inetisService: InetisService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      customerType: fb.control(null),
      customerId: fb.control(null),
      customer: fb.control(null),
      registrationNumber: fb.control(0),
      name: fb.control(null, Validators.required),
      nameLong: fb.control(null),
      url: fb.control(null),
      email: fb.control(null, [emailValidator]),
      phone: fb.control(null),
      mobile: fb.control(null),
      fax: fb.control(null),
      vat: fb.control(null),
      company: fb.control(false),
      address: fb.group({
        id: fb.control(null),
        street1: fb.control(null, Validators.required),
        street2: fb.control(null),
        city: fb.control(null),
        postal: fb.control(null, Validators.required),
        postalCode: fb.control(null, Validators.required),
        stateOrProvince: fb.control(null),
        country: fb.control(null),
      }),
      deliveryAddress: null,
      contacts: null,
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.bussinesUnit = this.config.data.bussinesUnit;
      this.customer = this.config.data.customer;
    }
    if (this.bussinesUnit && this.bussinesUnit.id) {
      this.form.patchValue(this.bussinesUnit);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  companySwitch(): void {
    if (this.form.get('company').value) {
      this.form.get('vat').enable();
    } else {
      this.form.get('vat').reset();
      this.form.get('vat').disable();
    }
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const bussinesUnit = {...this.bussinesUnit, ...this.form.getRawValue()};
    // TODO: We need this for now because we do't want to choose everytime for BU and we will not need this in the future
    bussinesUnit.customerType = this.customer.customerType;
    bussinesUnit.customer = this.customer;
    bussinesUnit.customerId = bussinesUnit.customerId === '' ? null : bussinesUnit.customerId;
    if (bussinesUnit.id) {
      this.customersService.update(bussinesUnit.id, bussinesUnit)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated bussines unit: ' + bussinesUnit.name,
            });
            this.ref.close(bussinesUnit);
          },
          error => {
            console.log(error);
          });
    } else {
      this.customersService.create(bussinesUnit)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created bussines unit: ' + bussinesUnit.name,
            });
            this.ref.close(bussinesUnit);
          },
          error => {
            console.log(error);
          });
    }
  }
}
