<form [formGroup]="form">
  <div class="form-row" formArrayName="answers">
    <div class="form-group mb-1" *ngFor="let answer of answersFormArray; let i = index;" [formGroupName]="i">
      <div class="form-row">
        <div class="form-group mb-2">
          <p *ngIf="answer.get('type').value !== formElementTypeMultiEnumSelect">{{i + 1}}. {{answer.get('question').value.sl.primary}}</p>
          <p *ngIf="answer.get('type').value === formElementTypeMultiEnumSelect">{{i + 1}}. {{answer.get('question').value.sl.primary}} {{'CHECKLIST.MULTIPLE_AVAILABLE' | translate}}</p>
        </div>
        <div *ngIf="answer.get('type').value === formElementTypeEnumInput" class="form-group mb-2">
          <input id="input" type="text" pInputText formControlName="text"/>
        </div>
        <div *ngIf="answer.get('type').value === formElementTypeEnumSelect" class="form-group mb-2">
          <p-listbox [options]="answer.get('availableAnswers').value" formControlName="select">
            <ng-template let-answer pTemplate="answer">
<!--              {{(answer|keyvalue)[0].value}}-->
              {{answer.sl}}
            </ng-template>
          </p-listbox>
          <div class="mt-3 mb-3">
            <label class="mr-2" for="otherSelect">{{answer.get('question').value.sl.secondary}}</label>
            <input id="otherSelect" type="text" pInputText formControlName="text"/>
          </div>
        </div>
        <div *ngIf="answer.get('type').value === formElementTypeMultiEnumSelect" class="form-group mb-2">
          <p-listbox [options]="answer.get('availableAnswers').value" optionLabel="id" formControlName="multiSelect"
                     [multiple]="true">
            <ng-template let-answer pTemplate="answer">
              {{answer.sl}}
            </ng-template>
          </p-listbox>
          <div class="mt-3 mb-3">
            <label class="mr-2" for="otherMultiSelect">{{answer.get('question').value.sl.secondary}}</label>
            <input id="otherMultiSelect" type="text" pInputText formControlName="text"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" (click)="save()" [disabled]="form.invalid"
            [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
