<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-4">
      <label for="periodOfPotentialDeal" class="required-mark" translate>OPPORTUNITIES.DATE_REALIZATION</label>
      <p-calendar id="periodOfPotentialDeal"
                  formControlName="periodOfPotentialDeal"
                  dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="field col-12 md:col-4">
      <label for="customer" class="required-mark" translate>OFFER.CUSTOMER</label>
      <p-autoComplete formControlName="customer"
                      [suggestions]="filtered"
                      (completeMethod)="filterCustomers($event)"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="customer"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="frequency" translate>COMMON.FREQUENCY</label>
      <p-autoComplete formControlName="frequency"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/frequencies')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="frequency"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-8">
      <label for="description" translate>PRODUCT.DESCRIPTION</label>
      <textarea id="description" type="text" pInputText formControlName="description"></textarea>
    </div>
    <div class="field col-12 md:col-4">
      <label for="probability" translate>COMMON.PROBABILITY</label>
      <p-autoComplete formControlName="probability"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/probabilities')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="probability"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="productGroup" class="required-mark" translate>COMMON.PRODUCT_GROUP</label>
      <p-autoComplete formControlName="productGroup"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/productGroups')"
                      (onSelect)="clearProducts()"
                      (onClear)="clearProducts()"
                      [dropdown]="true"
                      type="search"
                      forceSelection="true"
                      appendTo="body"
                      id="productGroup"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="product" translate>COMMON.PRODUCT</label>
      <p-autoComplete formControlName="product"
                      [suggestions]="filtered"
                      (completeMethod)="filterProducts($event, form.get('productGroup').value != null ? form.get('productGroup').value.id : null)"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="product"
                      field="description">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="status" translate>COMMON.STATUS</label>
      <p-autoComplete formControlName="status"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/statuses')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="status"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="volume" translate>OFFER.QUANTITY</label>
      <input id="volume" type="number" pInputText formControlName="volume">
    </div>
    <div class="field col-12 md:col-4">
      <label for="volumeUnit" translate>COMMON.UNIT</label>
      <p-autoComplete formControlName="volumeUnit"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/units')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="volumeUnit"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="stage" translate>COMMON.STAGE</label>
      <p-autoComplete formControlName="stage"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/stages')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="stage"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="value" translate>COMMON.VALUE</label>
      <input id="value" type="number" pInputText formControlName="value">
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" styleClass="p-button-success" (onClick)="save()"
            [disabled]="form.invalid"
            [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
