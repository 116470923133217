import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../models/product.model';

@Injectable()
export class CodelistsService {

  constructor(
    private http: HttpClient
    ) { }

  getAll(codelist: any): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + '/codelists/' + codelist);
  }

  get(id: any, codelist: any): Observable<any> {
    return this.http.get(`${environment.apiUrl + '/codelists/' + codelist}/${id}`);
  }

  create(data: any, codelist: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/codelists/' + codelist, data);
  }

  update(id: any, data: any, codelist: any): Observable<any> {
    return this.http.put(`${environment.apiUrl + '/codelists/' + codelist}/${id}`, data);
  }

  delete(id: any, codelist: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl + '/codelists/' + codelist}/${id}`);
  }

  deleteAll(codelist: any): Observable<any> {
    return this.http.delete(environment.apiUrl + '/codelists/' + codelist);
  }

  findByName(name: any, codelist: any): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.apiUrl + '/' + codelist}?name=${name}`);
  }
}
