<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-2">
      <label for="title" translate>CONTACT.TITLE</label>
      <p-autoComplete formControlName="title"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/contactTitles')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="title"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-5">
      <label for="firstName" class="required-mark" translate>CONTACT.FIRST_NAME</label>
      <span class="p-input-icon-left">
            <i class="pi pi-user"></i>
            <input id="firstName" type="text" pInputText formControlName="firstName">
      </span>
    </div>
    <div class="field col-12 md:col-5">
      <label for="lastName" class="required-mark" translate>CONTACT.LAST_NAME</label>
      <span class="p-input-icon-left">
            <i class="pi pi-user"></i>
            <input id="lastName" type="text" pInputText formControlName="lastName">
      </span>
    </div>
    <div class="field col-12 md:col-2">
      <label translate>CONTACT.TYPE</label>
      <p-multiSelect  [options]="contactTypes$|async"
                      formControlName="contactTypes"
                      optionLabel="name"
                      [showHeader]=false
      ></p-multiSelect>
    </div>


    <div class="field col-12 md:col-10">
      <label for="email" class="required-mark" translate>CONTACT.EMAIL</label>
      <input id="email" type="text" pInputText formControlName="email">
    </div>
    <div class="field col-12 md:col-2">
      <label for="firstName" translate>CONTACT.POSITION</label>
        <input id="position" type="text" pInputText formControlName="position">
    </div>
    <div class="field col-12 md:col-5">
      <label for="phone" translate>CONTACT.PHONE</label>
      <span class="p-input-icon-left">
            <i class="pi pi-phone"></i>
            <input id="phone" type="text" pInputText formControlName="phone">
      </span>
    </div>
    <div class="field col-12 md:col-5">
      <label for="mobile" translate>CONTACT.MOBILE</label>
      <span class="p-input-icon-left">
            <i class="pi pi-phone"></i>
            <input id="mobile" type="text" pInputText formControlName="mobile">
      </span>
    </div>
<!--      <div class="field col-12 md:col-12">
      <label for="title" class="required-mark" translate>CONTACT.POSITION</label>
      <p-checkbox [formControl]="form.controls['position']"
                   id="position">
      </p-checkbox>
    </div>-->
    <div class="field col-12 md:col-12">
      <label for="comment" translate>CONTACT.COMMENT</label>
      <textarea id="comment" type="text" pInputText formControlName="comment"></textarea>
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" (click)="save()" [disabled]="form.invalid"
          [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
