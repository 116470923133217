<div class="grid" *ngIf="customer$|async as customer">
  <div class="col-8">
    <h3>{{customer.name|empty}} &nbsp;
      <i class="pi pi-map-marker" style="font-size: 1.3rem"></i>
      {{customer.address|address|empty}}</h3>
  </div>
  <div class="col-4 flex justify-content-end mb-2">
    <p-button type="button" class="p-button-secondary pr-2" icon="pi pi-user-edit"
              [label]="'NOTE.ADD' | translate"
              (click)="openNoteModal(note)"></p-button>
    <p-button type="button" class="p-button-secondary" icon="pi pi-user-edit"
              [label]="'CUSTOMER.EDIT' | translate"
              (click)="editCustomerModal(customer, $event)"></p-button>
  </div>

  <!--    region CUSTOMER TABS  -->
  <div class="col-12">
    <div class="card">
      <p-tabView orientation="left">
        <p-tabPanel header="{{'CUSTOMER.CARD' | translate}}" leftIcon="pi pi-id-card">
          <!--    region  VERTICAL CUSTOMER CARDS    -->
          <div class="grid">
            <div class="col-12">
              <iframe title="ChemcolorBI" width="100%" height="650px" [src]="customer != null ? urlSafe : ''"
                      frameborder="0" allowFullScreen="true"></iframe>
            </div>
          </div>
          <!--    endregion  VERTICAL CUSTOMER CARDS    -->
        </p-tabPanel>
        <p-tabPanel header="{{'CONTACT.DETAILS' | translate}}" leftIcon="pi pi-user">
          <div class="p-fluid formgrid grid">
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMERS.NAME</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.name | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMERS.VAT</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.vat | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMER.LONG_NAME</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.nameLong | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMER.STREET1</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.address?.street1 | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMER.STREET2</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.address?.street2 | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMER.CITY</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.address?.city | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMERS.COUNTRY</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.address?.country.code2 | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMER.PHONE</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.phone | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMERS.EMAIL</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.email | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMER.FAX</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.fax | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>CUSTOMER.URL</p></div>
            <div class="col-12 md:col-6"><p>{{ customer.url | empty }}</p></div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{'CUSTOMER.RATING' | translate}}" leftIcon="pi pi-star">
          <div class="p-fluid formgrid grid">
            <div class="col-12 p-lg-8">
              <p-pickList [source]="rankingCodelist" [target]="ranking" sourceHeader="Lista za rangiranje"
                          targetHeader="Rangiranje kupca" dragdrop="true"
                          (onMoveToTarget)="saveRankings()"
                          (onMoveToSource)="saveRankings()"
                          (onTargetReorder)="saveRankings()"
                          (onMoveAllToTarget)="saveRankings()"
                          (onMoveAllToSource)="saveRankings()"
                          [responsive]="true" [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'300px'}">
                <ng-template let-ranking pTemplate="item">
                  <div>{{ranking.customerRankingType.name}}</div>
                </ng-template>
              </p-pickList>
            </div>
          </div>
          <div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{'CONTACT.CONTACTS' | translate}}" leftIcon="pi pi-user">
          <p-table #dt
                   [value]="contacts"
                   [lazy]="true"
                   (onLazyLoad)="pageChangedContacts($event)"
                   [(selection)]="selectedContact"
                   dataKey="id"
                   [rowHover]="true"
                   [rows]="contactsFilter.limit"
                   [totalRecords]="(contactsCount$ | async) || 0"
                   [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]"
                   [loading]="loading"
                   [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0"
                   [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                        <span class="p-input-icon-left">
                          <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                                    (click)="openContactModal(null)"
                                    [label]="'CONTACT.ADD_CONTACT' | translate"></p-button>
                        </span>
                <span class="p-input-icon-left">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                 placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
                      </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="firstName">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CONTACT.FIRST_NAME
                    <p-sortIcon field="firstName"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="lastName">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CONTACT.LAST_NAME
                    <p-sortIcon field="lastName"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="email">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CONTACT.EMAIL
                    <p-sortIcon field="email"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="phone">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CONTACT.PHONE
                    <p-sortIcon field="phone"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contact>
              <tr class="p-row">
                <td>{{ contact.firstName }}</td>
                <td>{{ contact.lastName }}</td>
                <td>{{ contact.email }}</td>
                <td>{{ contact.phone }}</td>
                <td style="text-align: center">
                  <!--<p-button type="button" class="p-button-secondary" icon="pi pi-user-edit"
                          (click)="editContactModal(contact, $event)"></p-button>-->
                  <button pButton type=button class="p-button-secondary table-button mr-2" icon="pi pi-user-edit"
                          (click)="openContactModal(contact)"></button>
                  <button pButton class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteContact(contact)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>CONTACT.NO_CONTACTS_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'CUSTOMER.BUSINESS_UNITS' | translate}}" leftIcon="pi pi-user">
          <p-table #dt [value]="businessUnits" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['name']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                            (click)="openBusinessUnitModal(null)"
                            [label]="'CUSTOMER.ADD_BUSINESS_UNIT' | translate"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CUSTOMER.NAME
                    <p-sortIcon field="name"></p-sortIcon>
                  </div>
                </th>
                <th>
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CONTACT.EMAIL
                  </div>
                </th>
                <th>
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CONTACT.PHONE
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-businessUnit>
              <tr class="p-row">
                <td>{{ businessUnit.name }}</td>
                <td>{{ businessUnit.email }}</td>
                <td>{{ businessUnit.phone }}</td>
                <td style="text-align: center">
                  <button pButton type=button class="p-button-secondary table-button mr-2" icon="pi pi-user-edit"
                          (click)="openBusinessUnitModal(businessUnit)"></button>
                  <button pButton class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteBusinessUnit(businessUnit)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>CUSTOMER.NO_BUSINESS_UNITS_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.OFFERS' | translate}}" leftIcon="pi pi-id-card">
          <p-table #dt [value]="offers" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['offer.number', 'dateCreated', 'buyer.name']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                            (click)="openOfferModal(customer.id, null)"
                            [label]="'OFFERS.CREATE_OFFER' | translate"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="number">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.NUMBER
                    <p-sortIcon field="number"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="buyer.name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.CUSTOMER
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="dateCreated">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_CREATED
                    <p-sortIcon field="dateCreated"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="dateCreated">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_ISSUED
                    <p-sortIcon field="dateCreated"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="dateCreated">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_DELIVERY
                    <p-sortIcon field="dateCreated"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-offer>
              <tr class="p-row">
                <td>
                  {{offer.number}}
                </td>
                <td>
                  {{offer.buyer?.name}}
                </td>
                <td>
                  {{offer.dateCreated | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  {{offer.dateCreated | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  {{offer.dateCreated | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                          (click)="openOfferModal(null, offer)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteOffer(offer)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>OFFERS.NO_OFFERS_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.ORDERS' | translate}}" leftIcon="pi pi-check-square">
          <p-table #dt [value]="orders" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['order.number', 'dateCreated', 'buyer.name']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                            (click)="openOrderModal(customer.id, null)"
                            [label]="'ORDERS.CREATE_ORDER' | translate"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="number">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.NUMBER
                    <p-sortIcon field="number"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="buyer.name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.CUSTOMER
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="dateCreated">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_CREATED
                    <p-sortIcon field="dateCreated"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="dateCreated">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_ISSUED
                    <p-sortIcon field="dateCreated"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="dateCreated">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_DELIVERY
                    <p-sortIcon field="dateCreated"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
              <tr class="p-row">
                <td>
                  {{order.number}}
                </td>
                <td>
                  {{order.buyer?.name}}
                </td>
                <td>
                  {{order.dateCreated | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  {{order.dateCreated | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  {{order.dateCreated | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                          (click)="openOrderModal(null, order)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteOrder(order)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>ORDERS.NO_ORDERS_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.NOTES' | translate}}" leftIcon="pi pi-book">
          <p-table #dt
                   [value]="notes"
                   dataKey="id"
                   [rowHover]="true"
                   [rows]="filter.limit"
                   [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]"
                   [loading]="loading"
                   [paginator]="true"
                   sortField="modifiedDate"
                   [sortOrder]="-1"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0"
                   [globalFilterFields]="['title', 'createdDate', 'customer.name']">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 5rem"></th>
                <th pSortableColumn="title">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CUSTOMER.ADDRESS
                    <p-sortIcon field="number"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="customer.name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.CUSTOMER
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="modifiedBy">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.MODIFIED_BY
                    <p-sortIcon field="modifiedBy"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="modifiedDate">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_MODIFIED
                    <p-sortIcon field="modifiedDate"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="createdDate">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.DATE_CREATED
                    <p-sortIcon field="createdDate"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-note let-expanded="expanded">
              <tr class="p-row">
                <td>
                  <button type="button" pButton pRipple [pRowToggler]="note"
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>
                  {{note.title}}
                </td>
                <td>
                  {{note.customer.name}}
                </td>
                <td>
                  {{note.modifiedBy}}
                </td>
                <td>
                  {{note.modifiedDate | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  {{note.createdDate | date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                          *ngIf="auth.isOwner(note) || auth.isAdmin()"
                          (click)="openNoteModal(note)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          *ngIf="auth.isOwner(note) || auth.isAdmin()"
                          (click)="deleteNote(note)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-note>
              <tr>
                <td colspan="5" class="pl-3">
                  <span [innerHTML]="note.text"></span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>NOTES.NO_NOTES_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
          <!--<p-accordion>
            <div *ngFor="let note of notes">
              <p-accordionTab>
                <p-header class="p-d-flex p-justify-between" style="width: 100%; font-weight: 300;">
                  <span style="margin-top: auto; margin-bottom: auto;">
                    {{(note.createdDate|date:'dd MMMM yyyy HH:mm') + ' - ' + note.title}}
                  </span>
                  <div>
                    <button pbutton="" pripple="" type="button" icon="pi pi-times"
                            (click)="$event.stopPropagation(); openNoteModal(note)"
                            class="p-element p-ripple p-button-rounded p-button-success p-button-text p-button p-component p-button-icon-only">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true"></span>
                    </button>
                    <button pbutton="" pripple="" type="button" icon="pi pi-times"
                            (click)="$event.stopPropagation(); deleteNote(note)"
                            class="p-element p-ripple p-button-rounded p-button-danger p-button-text p-button p-component p-button-icon-only">
                      <span class="p-button-icon pi pi-times" aria-hidden="true"></span>
                    </button>
                  </div>
                </p-header>
                <span [innerHTML]="note.text"></span>
              </p-accordionTab>
            </div>
          </p-accordion>-->
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.CHECKLISTS' | translate}}" leftIcon="pi pi-check-square">
          <p-table #dt [value]="forms" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['title', 'description']">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="title">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CUSTOMER.ADDRESS
                    <p-sortIcon field="title"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-form>
              <tr class="p-row">
                <td>{{ form.title }}</td>
                <td style="text-align: right">
                  <button pButton type=button class="p-button-secondary table-button mr-2" icon="pi pi-user-edit"
                          (click)="openChecklistModal(form)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>CONTACT.NO_CONTACTS_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.ATTACHMENTS' | translate}}" leftIcon="pi pi-upload">
          <p-table #dt [value]="uploadedFiles" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['attachment.name', 'attachment.size']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button type="button" class="p-button-secondary pr-2" icon="pi pi-upload"
                            [label]="'ATTACHMENTS.ADD' | translate"
                            (click)="openModalUpload(id, 'CUSTOMERS')"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileName">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="fileName"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileSize">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.SIZE
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-attachment>
              <tr class="p-row">
                <td>
                  {{attachment.name}}
                </td>
                <td>
                  <i [ngClass]="attachmentsService.getFileContentType(attachment.fileContentType)" class="mr-3 pi text-primary text-xl"></i>
                  {{attachment.fileName}}
                </td>
                <td>

                  {{Math.floor(attachment.fileSize / 1024)}} kB
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-download"
                          (click)="downloadAttachment(attachment)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteAttachment(attachment)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
              </tr>
            </ng-template>
          </p-table>

        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <!--    endregion CUSTOMER TABS    -->

  <!--    region  ADD ORDER / OFFER BUTTONS  -->
  <div class="col-12">
    <div class="grid">
      <div class="col-12 md:col-4 xl:col-4">
        <div class="card widget-feature-box" (click)="addOffer()">
          <i class="pi pi-plus mr-2" style="font-size: 20px"></i>
          <i class="pi pi-fw pi-id-card" style="font-size: 40px"></i>
          <h2 translate>BUTTON.OFFERS</h2>
          <span translate>BUTTON.ADD_NEW_OFFER</span>
        </div>
      </div>
      <div class="col-12 md:col-4 xl:col-4">
        <div class="card widget-feature-box" (click)="addOrder()">
          <i class="pi pi-plus mr-2" style="font-size: 20px"></i>
          <i class="pi pi-fw pi-check-square" style="font-size: 40px"></i>
          <h2 translate>BUTTON.ORDERS</h2>
          <span translate>BUTTON.ADD_NEW_ORDER</span>
        </div>
      </div>
      <div class="col-12 md:col-4 xl:col-4">
        <div class="card widget-feature-box" (click)="addOpportunity()">
          <i class="pi pi-plus mr-2" style="font-size: 20px"></i>
          <i class="pi pi-fw pi-check-square" style="font-size: 40px"></i>
          <h2 translate>BUTTON.OPPORTUNITIES</h2>
          <span translate>BUTTON.ADD_NEW_OPPORTUNITY</span>
        </div>
      </div>
    </div>
  </div>
  <!--    endregion  ADD ORDER / OFFER BUTTONS    -->

</div>
<app-customer-full-modal [dialog]="customerDialog" *ngIf="customerForEdit"
                         [customer]="customerForEdit" (updatedCustomer)="customerDialogChanged($event)"
></app-customer-full-modal>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
