import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Codelist } from '../models/codelist.model';
import { CodelistsService } from '../service/codelists.service';
import {
  CodelistColorccFullModalComponent
} from '../core/modals/codelist-colorcc-full-modal/codelist-colorcc-full-modal.component';

@Component({
  selector: 'app-codelist-color-cc',
  templateUrl: './codelist-color-cc.component.html',
  styleUrls: ['./codelist-color-cc.component.scss']
})
export class CodelistColorCCComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Codelist[]> = new BehaviorSubject([]);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  codelists: Codelist[];
  count = 0;

  ref: DynamicDialogRef;

  loading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private translate: TranslateService,
    private codelistsService: CodelistsService,
  ) {
  }

  ngOnInit(): void {
    this.fetch();
  }

  ngOnDestroy(): void {
    this.items.complete();
    this.pending.complete();
  }

  fetch(): void {
    this.loading = true;
    this.codelistsService.getAll('colorsCc').subscribe(data => {
        this.codelists = data;
        this.count = data.length;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  openModal(input: Codelist): void {
    this.ref = this.dialogService.open(CodelistColorccFullModalComponent, {
      header: this.translate.instant('CODELIST.CODELIST_COLOR_CC'),
      width: '40vw',
      data: {codelist: input}
    });

    this.ref.onClose.subscribe((codelist: Codelist) => {
      if (codelist) {
        this.fetch();
      }
    });
  }

  delete(codelist: Codelist): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + codelist.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.codelistsService.delete(codelist.id, 'colorsCc')
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted codelist: ' + codelist.name,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

}
