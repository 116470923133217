import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApi, ListData, StandardFilter } from '../core/api/base-api';
import { Project } from '../models/project.model';
import { Sensor } from '../models/sensor.model';

export class SensorsFilter extends StandardFilter {
  search?: string;
}

@Injectable()
export class SensorsService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: SensorsFilter): Observable<ListData<Sensor>> {
    return this.list<Sensor>(`${environment.apiUrl}/sensors`, { params: this.params(filter) });
  }

  findById(id: string, filter: SensorsFilter): Observable<ListData<Sensor>> {
    return this.list<Sensor>(`${environment.apiUrl}/sensors/${id}`, { params: this.params(filter) });
  }
}
