import { Injectable } from '@angular/core';

export class FeatureCollection {
  type: string;

  features: Feature[];
}

export class Feature {
  type: string;

  properties?: FeatureProperty;

  geometry: FeatureGeometry;
}

export class FeatureProperty {
  name: string;

  square?: number;
}

export class FeatureGeometry {
  type: string;

  coordinates: number[][][];
}

const buildingData: FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[
          /*[10, -80],
          [-80, -80],
          [-80, 80],
          [40, 80],
          [40, -20],
          [100, -20],
          [100, -80],
          [30, -80],
          [30, -74],
          [34, -74],
          [34, -68],
          [40, -68],
          [40, -74],
          [94, -74],
          [94, -26],
          [40, -26],
          [40, -60],
          [34, -60],
          [34, 74],
          [-74, 74],
          [-74, 30],
          [10, 30],
          [10, 24],
          [-74, 24],
          [-74, -24],
          [10, -24],
          [10, -30],
          [-74, -30],
          [-74, -74],
          [10, -74],*/
          [-130, -80],
          [130, -80],
          [130, 80],
          [-130, 80],
        ]],
      },
    },
  ],
};

const roomsData: FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: 'Pilot. Proiz.',
        square: 1,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [-126, 76],
          [-94, 76],
          [-94, 4],
          [-126, 4],
        ]],
      },
    }, {
      type: 'Feature',
      properties: {
        name: 'Mikornizacija.',
        square: 2,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [-86, 76],
          [-4, 76],
          [-4, 4],
          [-86, 4],
        ]],
      },
    }, {
      type: 'Feature',
      properties: {
        name: 'Mešanica 1',
        square: 3,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [126, 76],
          [69, 76],
          [69, 4],
          [126, 4],
        ]],
      },
    }, {
      type: 'Feature',
      properties: {
        name: 'Mešanica 2',
        square: 4,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [61, 76],
          [4, 76],
          [4, 44],
          [61, 44],
        ]],
      },
    }, {
      type: 'Feature',
      properties: {
        name: 'Mešanica 3',
        square: 5,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [61, 4],
          [4, 4],
          [4, 36],
          [61, 36],
        ]],
      },
    }, {
      type: 'Feature',
      properties: {
        name: 'Mešanica 4',
        square: 6,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [61, -4],
          [-26, -4],
          [-26, -76],
          [61, -76],
        ]],
      },
    }, {
      type: 'Feature',
      properties: {
        name: 'Polnilnica 1',
        square: 7,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [-34, -4],
          [-126, -4],
          [-126, -76],
          [-34, -76],
        ]],
      },
    },
  ],
};

@Injectable()
export class FloorPlanService {
  getBuildingData(): FeatureCollection {
    return buildingData;
  }

  getRoomsData(): FeatureCollection {
    return roomsData;
  }
}
