<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-3">
      <label for="customer" class="required-mark" translate>PROJECT.BUYER</label>
      <p-autoComplete formControlName="buyer"
                      [suggestions]="filtered"
                      (completeMethod)="filterCustomers($event)"
                      [dropdown]="true"
                      (onSelect)="selectBuyer($event)"
                      forceSelection="true"
                      appendTo="body"
                      id="customer"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-3">
      <label for="projectType" translate>PROJECT.TYPE</label>
      <p-autoComplete formControlName="projectType"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/projectTypes')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="projectType"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-3">
      <label for="dateStart" class="required-mark" translate>PROJECT.DATE_STARTED</label>
      <p-calendar id="dateStart"
                  formControlName="dateStart"
                  dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="field col-12 md:col-3">
      <label for="dateEnd" class="required-mark" translate>PROJECT.DATE_ENDED</label>
      <p-calendar id="dateEnd"
                  formControlName="dateEnd"
                  dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="field col-12 md:col-3">
      <label for="projectStatus" translate>PROJECT.STATUS</label>
      <p-autoComplete formControlName="projectStatus"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/projectStatuses')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="projectStatus"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-3">
      <label for="name" class="required-mark" translate>CODELIST.NAME</label>
      <input id="name" type="text" pInputText formControlName="name">
    </div>
    <div class="field col-12 md:col-6">
      <label for="description" translate>CODELIST.DESCRIPTION</label>
      <textarea id="description" type="text" pInputText formControlName="description"></textarea>
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" styleClass="p-button-success" (onClick)="save()"
            [disabled]="form.invalid"
            [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
