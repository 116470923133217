<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-9">
      <label for="name" class="required-mark" translate>PRODUCT.NAME</label>
      <input id="name" type="text" pInputText formControlName="name">
    </div>
    <div class="field col-12 md:col-3">
      <label for="code" translate>PRODUCT.CODE</label>
      <input id="code" type="text" pInputText formControlName="code">
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-9">
      <label for="description" translate>PRODUCT.DESCRIPTION</label>
      <textarea id="description" type="text" pInputText formControlName="description"></textarea>
    </div>
    <div class="field col-12 md:col-3">
      <label for="pantheon" translate>PRODUCT.PANTHEON</label>
      <input id="pantheon" type="text" pInputText formControlName="pantheon">
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-4">
      <label for="colorCc" translate>PRODUCT.COLORCC</label>
      <button pButton pRipple type="button" icon="pi pi-plus"
              class="p-button-rounded p-button-text ml-2"
              (click)="addNewColorModal(null)"></button>
      <p-autoComplete formControlName="colorCc"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/colorsCc')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="colorCc"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="colorNcs" translate>PRODUCT.COLORNCS</label>
      <p-autoComplete formControlName="colorNcs"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/colorsNcs')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="colorNcs"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="colorRal" translate>PRODUCT.COLORRAL</label>
      <p-autoComplete formControlName="colorRal"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/colorsRal')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="colorRal"
                      field="code">
      </p-autoComplete>
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-4">
      <label for="gloss" translate>PRODUCT.GLOSS</label>
      <p-autoComplete formControlName="gloss"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/glosses')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="gloss"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="type" translate>PRODUCT.TYPE</label>
      <p-autoComplete formControlName="type"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/types')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="type"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="category" translate>PRODUCT.CATEGORY</label>
      <p-autoComplete formControlName="category"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/categories')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="category"
                      field="name">
      </p-autoComplete>
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-4">
      <label for="density" translate>PRODUCT.DENSITY</label>
      <input id="density" type="text" pInputText formControlName="density">
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" styleClass="p-button-success" (onClick)="save()"
            [disabled]="form.invalid"
            [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
