import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Observable, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { I18nUtilService, Language } from './core/util/i18n-util.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  items: MenuItem[];

  displayUsername: string;

  language$: Observable<Language | null> = this.i18nUtil.language$;
  languages$: Observable<Language[]> = this.i18nUtil.languages$;

  constructor(public breadcrumbService: BreadcrumbService,
              public app: AppComponent,
              public appMain: AppMainComponent,
              private i18nUtil: I18nUtilService,
              private keycloak: KeycloakService
  ) {
    this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
      this.items = response;
    });
  }

  ngOnInit(): void {
    this.keycloak.loadUserProfile().then( profile => {
      this.displayUsername = ((profile.firstName || '') + ' ' + (profile.lastName || '')).trim();
    });

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  selectLanguage(lang: Language): void {
    this.i18nUtil.select(lang);
  }

  logout(): void {
    this.keycloak.logout();
  }
}
