import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InetisService} from '../../service/inetis.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomersService} from '../../service/customers.service';
import {Contact, Customer} from '../../models/customer.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {ContactsFilter, ContactsService} from '../../service/contacts.service';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {
  BussinesUnitFullModalComponent
} from '../../core/modals/bussines-unit-full-modal/bussines-unit-full-modal.component';
import {OffersService} from '../../service/offers.service';
import {Offer} from '../../models/offer.model';
import {OfferFullModalComponent} from '../../core/modals/offer-full-modal/offer-full-modal.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {OrdersService} from '../../service/orders.service';
import {Order} from '../../models/order.model';
import {OrderFullModalComponent} from '../../core/modals/order-full-modal/order-full-modal.component';
import {ChecklistFullModalComponent} from '../../core/modals/checklist-full-modal/checklist-full-modal.component';
import {Form} from '../../models/form.model';
import {FormsService} from '../../service/forms.service';
import {NotesFilter, NotesService} from '../../service/notes.service';
import {NoteFullModalComponent} from '../../core/modals/note-full-modal/note-full-modal.component';
import {Note} from '../../models/note.model';
import {ContactFullModalComponent} from '../../core/modals/contact-full-modal/contact-full-modal.component';
import {CodelistsService} from '../../service/codelists.service';
import {BreadcrumbService} from '../../app.breadcrumb.service';
import {environment} from '../../../environments/environment';
import {AttachmentsService} from '../../service/attachments.service';
import {map} from 'rxjs/operators';
import {AuthService} from '../../service/auth.service';
import {Project} from '../../models/project.model';
import {ProjectFullModalComponent} from '../../core/modals/project-full-modal/project-full-modal.component';
import {AttachmentFullModalComponent} from '../../core/modals/attachment-full-modal/attachment-full-modal.component';
import {SharedAttachmentService} from '../../service/sharedAttachments.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {

  constructor(
    private customersService: CustomersService,
    private contactsService: ContactsService,
    private messageService: MessageService,
    private offersService: OffersService,
    private ordersService: OrdersService,
    private formsService: FormsService,
    private notesService: NotesService,
    private confirmationService: ConfirmationService,
    private codelistsService: CodelistsService,
    private inetisService: InetisService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    public dialogService: DialogService,
    private breadcrumbService: BreadcrumbService,
    private attachmentsService: AttachmentsService,
    private sharedAttachmentService: SharedAttachmentService,
    public auth: AuthService,
  ) {
    this.breadcrumbService.setItems([
      {label: 'File'}
    ]);
  }

  private contactsItems: BehaviorSubject<Contact[]> = new BehaviorSubject([]);
  private contactsCount: BehaviorSubject<number> = new BehaviorSubject(0);
  private contactsPending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  contactsItems$: Observable<Contact[]> = this.contactsItems.asObservable();
  contactsCount$: Observable<number> = this.contactsCount.asObservable();
  contactsPending$: Observable<boolean> = this.contactsPending.asObservable();
  contactsEmpty$: Observable<boolean> = this.contactsCount$.pipe(map(count => count === 0));

  loading = false;
  id: string;
  fileName: string;
  file: File;
  contacts: Contact[];
  businessUnits: Customer[];
  offers: Offer[];
  orders: Order[];
  forms: Form[];
  // Customer-full-modal
  customerDialog: boolean;
  updatedCustomer: Customer;
  customerForEdit: Customer;
  urlSafe: SafeResourceUrl;
  url = 'https://app.powerbi.com/reportEmbed?reportId=24a356a8-c7e8-4ee6-8c3f-ef3d65287e07&autoAuth=true&ctid=ad3d7dbe-454c-42a8-8174-4b6ada82284a';
  ref: DynamicDialogRef;
  rankingCodelist: any[] = [];
  ranking: any[] = [];
  notes: any[];
  note: Note;
  // Attachments
  uploadedFiles: any[] = [];
  attachments: any[] = [];
  deletedAttachments: any[] = [];
  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef; files  = [];
  uploadUrl = ``;

  private customer: BehaviorSubject<Customer> = new BehaviorSubject(null);
  customer$: Observable<Customer> = this.customer.asObservable();

  filter: NotesFilter = new NotesFilter(0, 10);
  contactsFilter: ContactsFilter = new ContactsFilter(0, 10);
  selectedContact: Contact;

  protected readonly Math = Math;

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.uploadUrl = `${environment.apiUrl}/attachments/CUSTOMERS/${this.id}`;
    this.filter.customerId = this.id;
    this.note = new Note();
    this.note.customer = new Customer();
    this.note.customer.id = this.id;
    this.fetch(this.id);
    this.fetchRankings();
    this.fetchContacts();
    this.fetchBusinessUnits();
    this.fetchOffers();
    this.fetchOrders();
    this.fetchForms();
    this.loadIframeUrlWithCustomer();
    this.fetchNotes();
    this.fetchAttachments();
  }

  ngOnDestroy(): void {
    this.customer.complete();
  }

  customerDialogChanged(customer: Customer): void {
    if (customer) {
      this.updatedCustomer = customer;
      this.fetch(customer.id);
    } else {
      this.updatedCustomer = {};
    }
  }

  fetch(id: string): void {
    this.loading = true;
    this.customersService.get(id)
      .subscribe(
        data => {
          this.customer.next(data);
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  fetchRankings(): void {
    this.codelistsService.getAll('customerRankingTypes')
      .subscribe(
        data => {
          this.loading = false;
          data.forEach(element => {
            this.rankingCodelist.push(
              {
                id: null,
                customerRankingType: element
              });
          });
          setTimeout(() => {
            this.customersService.getRankings(this.id)
              .subscribe(
                data2 => {
                  this.loading = false;
                  this.ranking = data2;
                  this.ranking.forEach(ranking => {
                    this.rankingCodelist.forEach((value, index) => {
                      if (ranking.customerRankingType.id === value.customerRankingType.id) {
                        this.rankingCodelist.splice(index, 1);
                      }
                    });
                  });
                },
                error => {
                  console.log(error);
                });
          }, 0);
        },
        error => {
          console.log(error);
        });
  }

  fetchContacts(): void {
    this.contactsService.listContactsByCustomerId(this.id, this.contactsFilter)
      .subscribe(
        data => {
            this.contactsPending.next(false);
            this.contactsItems.next(data.values);
            this.contactsCount.next(data.count);
            this.contacts = data.values;
            this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  fetchBusinessUnits(): void {
    this.contactsService.listBusinessUnitsByCustomerId(this.id)
      .subscribe(
        data => {
          this.businessUnits = data;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  fetchOffers(): void {
    this.offersService.getAllForBuyer(this.id)
      .subscribe(
        data => {
          this.offers = data;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  fetchOrders(): void {
    this.ordersService.getAllForBuyer(this.id)
      .subscribe(
        data => {
          this.orders = data;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  fetchForms(): void {
    this.formsService.find().subscribe(data => {
      if (data) {
        this.forms = data;
        this.loading = false;
      }
    });
  }

  fetchNotes(): void {
    this.notesService.find(this.filter)
      .subscribe(
        data => {
          this.notes = data.values;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  pageChangedContacts(event: LazyLoadEvent): void {
    this.contactsFilter.limit = event.rows;
    this.contactsFilter.offset = event.first;
    this.contactsFilter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.contactsFilter.orderBy = '-' + event.sortField;
    } else {
      this.contactsFilter.orderBy = event.sortField;
    }
    this.fetchContacts();
  }

  openNoteModal(note: Note): void {
    this.ref = this.dialogService.open(NoteFullModalComponent, {
      header: this.translate.instant('NOTES.NOTE'),
      width: '98vw',
      data: {
        note
      }
    });

    // tslint:disable-next-line:no-shadowed-variable
    this.ref.onClose.subscribe((note: Note) => {
      if (note) {
        this.fetchNotes();
      }
    });
  }

  openContactModal(contact: Contact): void {
    this.ref = this.dialogService.open(ContactFullModalComponent, {
      header: this.translate.instant('CONTACT.CONTACT'),
      width: '50vw',
      data: {
        contact,
        customer: this.customer.getValue()
      }
    });

    // tslint:disable-next-line:no-shadowed-variable
    this.ref.onClose.subscribe((contact: Contact) => {
      if (contact) {
        this.fetchContacts();
      }
    });
  }

  deleteNote(note: Note): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete note ' + note.title + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notesService.delete(note.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('SUCCESS.SUCCESS'),
                detail: this.translate.instant('SUCCESS.DELETED.NOTE') + ' ' + note.title,
              });
              this.fetchNotes();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  loadIframeUrlWithCustomer(): void {
    this.customer$.subscribe(customer => {
      if (customer != null) {
        this.url = this.url + '&filter=tHE_SetSubj%2FacCode+eq+%27' + customer.vat + '%27';
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
    });
  }

  editCustomerModal(customer: Customer, event): void {
    this.customerForEdit = {...customer};
    this.customerDialog = event;
  }

  addOffer(): void {
    this.router.navigate(['/offers'], {
      relativeTo: this.route,
      queryParams: {
        create: '1',
        buyer: this.id
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: true
      // do not trigger navigation
    });
  }

  addOrder(): void {
    this.router.navigate(['/orders'], {
      relativeTo: this.route,
      queryParams: {
        create: '1',
        buyer: this.id
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: true
      // do not trigger navigation
    });
  }

  addOpportunity(): void {
    this.router.navigate(['/opportunities'], {
      relativeTo: this.route,
      queryParams: {
        create: '1',
        customer: this.id
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: true
      // do not trigger navigation
    });
  }

  deleteContact(contact: Contact): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + contact.firstName + contact.lastName + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.contactsService.delete(contact.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted contact: ' + contact.firstName + contact.lastName,
              });
              this.fetchContacts();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  openBusinessUnitModal(input: Customer): void {
    this.ref = this.dialogService.open(BussinesUnitFullModalComponent, {
      header: this.translate.instant('CUSTOMER.BUSINESS_UNIT'),
      width: '70vw',
      data: {bussinesUnit: input, customer: this.customer.value}
    });

    this.ref.onClose.subscribe((bussinesUnit: Customer) => {
      if (bussinesUnit) {
        this.fetchBusinessUnits();
      }
    });
  }

  deleteBusinessUnit(bu: Customer): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + bu.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.customersService.delete(bu.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted business unit: ' + bu.name,
              });
              this.fetchBusinessUnits();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  viewOffer(id): void {
    this.router.navigate(['offer/' + id]);
  }

  openOfferModal(buyer: string, input: Offer): void {
    this.ref = this.dialogService.open(OfferFullModalComponent, {
      header: this.translate.instant('OFFER.OFFER'),
      width: '98vw',
      height: '96vh',
      data: {
        buyer,
        offer: input
      }
    });

    this.ref.onClose.subscribe((offer: Offer) => {
      if (offer) {
        this.fetchOffers();
      }
    });
  }

  deleteOffer(offer: Offer): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete offer ' + offer.number + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.offersService.delete(offer.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted offer: ' + offer.number,
              });
              this.fetchOffers();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  openOrderModal(buyer: string, input: Order): void {
    this.ref = this.dialogService.open(OrderFullModalComponent, {
      header: this.translate.instant('ORDER.ORDER'),
      width: '98vw',
      height: '96vh',
      data: {
        buyer,
        offer: input
      }
    });

    this.ref.onClose.subscribe((order: Order) => {
      if (order) {
        this.fetchOrders();
      }
    });
  }

  deleteOrder(order: Order): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete order ' + order.number + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.offersService.delete(order.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted order: ' + order.number,
              });
              this.fetchOrders();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  openChecklistModal(form: Form): void {
    this.ref = this.dialogService.open(ChecklistFullModalComponent, {
      header: this.translate.instant('MENU.CHECKLISTS'),
      width: '60vw',
      data: {
        customer: this.customer,
        inputForm: form
      }
    });

    this.ref.onClose.subscribe((inputForm: Form) => {
      if (inputForm) {
        this.fetchForms();
      }
    });
  }

  saveRankings(): void {
    this.loading = true;
    this.customersService.saveRankings(this.id, this.ranking)
      .subscribe(
        data => {
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  fetchAttachments(): void {
        this.attachmentsService.findById(this.id, 'CUSTOMERS')
          .subscribe(data => {
            this.uploadedFiles = data;
            this.loading = false;
          }, error => {
            console.log(error);
          });
  }

  openModalUpload(id: string, entityType: string): void {
    this.ref = this.dialogService.open(AttachmentFullModalComponent, {
      header: this.translate.instant('ATTACHMENTS.ADD'),
      width: '50vw',
      height: '25vh',
      data: {id, entityType}
    });

    this.ref.onClose.subscribe(() => {
        this.fetchAttachments();
    });
  }

  downloadAttachment(attachment: any) {
    this.sharedAttachmentService.downloadAttachment(attachment);
  }

  deleteAttachment(attachment: any) {
    this.sharedAttachmentService.deleteAttachment(attachment, this.fetchAttachments.bind(this));
  }
}
