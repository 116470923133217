import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article } from '../models/article.model';
import { BaseApi, ListData, StandardFilter } from '../core/api/base-api';

export class ArticlesFilter extends StandardFilter {
  search?: string;
  customerId?: number;
  orderBy?: string;
}

@Injectable()
export class ArticlesService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: ArticlesFilter): Observable<ListData<Article>> {
    return this.list<Article>(`${environment.apiUrl}/articles`, { params: this.params(filter) });
  }

  findById(id: number): Observable<Article> {
    return this.http.get<Article>(`${environment.apiUrl}/articles/${id}`);
  }

  findByProductId(productId: number, filter: ArticlesFilter): Observable<ListData<Article>> {
    return this.list<Article>(`${environment.apiUrl}/articles/product/${productId}`, { params: this.params(filter) });
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/articles`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/articles/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/articles/${id}`);
  }

  deleteAll(): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/articles`);
  }

  findByName(name: any): Observable<Article[]> {
    return this.http.get<Article[]>(`${environment.apiUrl}/articles?name=${name}`);
  }
}
