import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BaseApi, ListData, StandardFilter} from '../core/api/base-api';
import {Import} from '../models/import.model';
import {Error} from '../models/error.model';

export class ImportsFilter extends StandardFilter {
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImportsService extends BaseApi  {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: ImportsFilter): Observable<ListData<Import>> {
    return this.list<Import>(`${environment.apiUrl}/imports/logs`, { params: this.params(filter) });
  }

  findById(importLogId: any): Observable<ListData<Error>> {
    return this.list<Error>(`${environment.apiUrl}/imports/data?importLogId=${importLogId}`);
  }
}
