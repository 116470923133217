<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>OFFERS.OFFERS</h5>
      <p-table #dt
               [value]="offers"
               [lazy]="true"
               (onLazyLoad)="pageChanged($event)"
               [(selection)]="selectedOffer"
               dataKey="id"
               [rowHover]="true"
               [rows]="filter.limit"
               [totalRecords]="(count$ | async) || 0"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,15,20,50,200]"
               [loading]="loading"
               [paginator]="true"
               sortField="dateCreated"
               [sortOrder]="-1"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0"
               [globalFilterFields]="['offer.number', 'dateCreated', 'buyer.name']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
              <p-button icon="pi pi-plus" class="p-button-raised p-w-100" (click)="openModal(null, null)"
                      [label]="'OFFERS.CREATE_OFFER' | translate"></p-button>
            </span>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="number">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.NUMBER
                <p-sortIcon field="number"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="buyer.name">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.CUSTOMER
                <p-sortIcon field="buyer.name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="createdBy">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.CREATED_BY
                <p-sortIcon field="createdBy"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="project.name">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFER.PROJECT
                <p-sortIcon field="project.name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="dateCreated">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.DATE_CREATED
                <p-sortIcon field="dateCreated"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="dateValid">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.DATE_ISSUED
                <p-sortIcon field="dateValid"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="dateDelivery">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.DATE_DELIVERY
                <p-sortIcon field="dateDelivery"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="priceTotalGross">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFER.PRICE
                <p-sortIcon field="priceTotalGross"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-offer>
          <tr class="p-row">
            <td (click)="viewOffer(offer.id)" style="cursor: pointer;">
              {{offer.number}}
            </td>
            <td (click)="viewCustomer(offer.buyer.id)" style="cursor: pointer;">
              {{offer.buyer?.name}}
            </td>
            <td>
              {{offer.createdBy}}
            </td>
            <td>
              {{offer.project?.name}}
            </td>
            <td>
              {{offer.dateCreated | date: 'dd.MM.yyyy'}}
            </td>
            <td>
              {{offer.dateValid | date: 'dd.MM.yyyy'}}
            </td>
            <td>
              {{offer.dateDelivery | date: 'dd.MM.yyyy'}}
            </td>
            <td>
              {{offer.priceTotalGross|currency: 'EUR':'symbol'|empty}}
            </td>
            <td>
              <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                      *ngIf="auth.isOwner(offer) || auth.isAdmin()"
                      (click)="openModal(null, offer)"></button>
              <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                      *ngIf="auth.isOwner(offer) || auth.isAdmin()"
                      (click)="delete(offer)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>OFFERS.NO_OFFERS_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
