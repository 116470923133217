import { Pipe, PipeTransform } from '@angular/core';
import * as currency from 'currency.js';

@Pipe({
  name: 'niceNumber'
})
export class NiceNumberPipe implements PipeTransform {

  transform(value: number, {separator, decimal, precision} = {} as any): string | null {
    if (!value && value !== 0) {
      return null;
    }
    return currency(value, {separator: separator || ' ', decimal: decimal || ',', precision: precision || 0}).format({
      symbol: ''
    });
  }
}
