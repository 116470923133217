<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="name" class="required-mark" translate>CONTACT.TITLE</label>
      <input id="name" type="text" pInputText formControlName="title">
    </div>
    <div class="field col-12">
      <label for="description" translate>CODELIST.DESCRIPTION</label>
      <p-editor id="description"
                formControlName="text"
                [style]="{'height':'320px'}">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button class="ql-strike" aria-label="Strike"></button>
          </span>
          <span class="ql-formats">
            <button type="button" class="ql-list" value="ordered"></button>
            <button type="button" class="ql-list" value="bullet"></button>
          </span>
          <span class="ql-formats">
            <!-- Add subscript and superscript buttons -->
            <button class="ql-script" value="sub"></button>
            <button class="ql-script" value="super"></button>
          </span>
        </ng-template>
      </p-editor>
    </div>
  </div>
</form>
<p-footer>
    <p-button icon="pi pi-check" class="p-button-raised" styleClass="p-button-success" (onClick)="save()"
              [disabled]="form.invalid"
              [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
