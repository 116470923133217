import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {Project} from '../../../models/project.model';
import {Customer} from '../../../models/customer.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ProjectsService} from '../../../service/projects.service';
import {CodelistsService} from '../../../service/codelists.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {CustomersService} from '../../../service/customers.service';
import {FormsService} from '../../../service/forms.service';
import {Form, FormDefinition, FormElementTypeEnum} from '../../../models/form.model';

@Component({
  selector: 'app-checklist-full-modal',
  templateUrl: './checklist-full-modal.component.html',
  styleUrls: ['./checklist-full-modal.component.scss']
})
export class ChecklistFullModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);
  saving$: Observable<boolean> = this.saving.asObservable();

  form: UntypedFormGroup;
  answers: UntypedFormArray | null = null;
  formElementTypeEnumInput: FormElementTypeEnum = FormElementTypeEnum.INPUT;
  formElementTypeEnumSelect: FormElementTypeEnum = FormElementTypeEnum.SELECT;
  formElementTypeMultiEnumSelect: FormElementTypeEnum = FormElementTypeEnum.MULTI_SELECT;

  get answersFormArray(): any[] {
    return (this.form.get('answers') as any).controls;
  }

  @Input() project: Project;
  @Input() customer: Customer;
  @Input() inputForm: Form;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private projectsService: ProjectsService,
    private codelistsService: CodelistsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    private customersService: CustomersService,
    private formsService: FormsService,
    private fb: UntypedFormBuilder) {
    this.form = fb.group({
      answers: fb.array([])
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.inputForm = this.config.data.inputForm;
      this.customer = this.config.data.customer?.value;
      this.project = this.config.data.project?.value;
    }
    if (this.customer && this.customer.id) {
      this.formsService.findByCustomerId(this.inputForm.id, this.customer.id).subscribe(res => {
        if (res) {
          this.addQuestions(res.formsDefinitions);
        } else {
          this.answers = this.form.get('answers') as UntypedFormArray;
          this.answers.clear();
        }
      });
    } else if (this.project && this.project.id) {
      this.formsService.findByProjectId(this.inputForm.id, this.project.id).subscribe(res => {
        if (res) {
          this.addQuestions(res.formsDefinitions);
        } else {
          this.answers = this.form.get('answers') as UntypedFormArray;
          this.answers.clear();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const answers = this.form.get('answers') as UntypedFormArray;
    this.saving.next(true);

    const wrapper = this.inputForm;
    answers.controls.forEach(answer => {
      wrapper.formsDefinitions.forEach(formDefinition => {
        if (formDefinition.orders === answer.get('questionId').value) {
          if (formDefinition.formElement.formElementType.name === FormElementTypeEnum.INPUT) {
            formDefinition.text = answer.get('text').value;
          } else if (formDefinition.formElement.formElementType.name === FormElementTypeEnum.SELECT) {
            formDefinition.select = {value: answer.get('select').value};
            formDefinition.text = answer.get('text').value;
          } else if (formDefinition.formElement.formElementType.name === FormElementTypeEnum.MULTI_SELECT) {
            formDefinition.multiSelect = answer.get('multiSelect').value;
            formDefinition.text = answer.get('text').value;
          }
        }
      });
    });
    if (this.customer && this.customer.id) {
      this.formsService.updateForCustomer(this.customer.id, wrapper.id , wrapper)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('SUCCESS.SUCCESS'),
              detail: this.translate.instant('CHECKLIST.SUCCESS_TITLE'),
            });
            this.ref.close(wrapper);
          },
          error => {
            console.log(error);
          });
    } else if (this.project && this.project.id) {
      this.formsService.updateForProject(this.project.id, wrapper.id, wrapper)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('SUCCESS.SUCCESS'),
              detail: this.translate.instant('CHECKLIST.SUCCESS_TITLE'),
            });
            this.ref.close(wrapper);
          },
          error => {
            console.log(error);
          });
    }
  }

  addQuestions(formDefinitions: FormDefinition[]): void {
    this.answers = this.form.get('answers') as UntypedFormArray;
    // sort questions by orderId ascending
    formDefinitions.sort((a, b) => (a.orders > b.orders) ? 1 : -1);
    formDefinitions.forEach((formDefinition) => {
      this.answers.push(this.createAnswer(formDefinition));
    });
  }

  getValidity(i): boolean {
    return (this.form.get('answers') as UntypedFormArray).controls[i].invalid;
  }

  private createAnswer(question: FormDefinition): UntypedFormGroup {
    return this.fb.group({
      projectId: this.fb.control(this.project?.id),
      customerId: this.fb.control(this.customer?.id),
      questionId: this.fb.control(question.orders),
      question: this.fb.control(question.formElement.labels),
      type: this.fb.control(question.formElement.formElementType.name),
      availableAnswers: this.fb.control(question.formElement.props?.options),
      select: this.fb.control(question.select?.value),
      multiSelect: this.fb.control(question.multiSelect),
      text: this.fb.control(question.text)
    });
  }

}
