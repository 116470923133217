import {Component, OnInit} from '@angular/core';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService, SharedModule} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Offer} from '../../models/offer.model';
import {map} from 'rxjs/operators';
import {PriceListsFilter} from '../../service/pricelists.service';
import {Import} from '../../models/import.model';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from 'primeng/dynamicdialog';
import {ImportsService} from '../../service/imports.service';
import {AuthService} from '../../service/auth.service';
import {Product} from '../../models/product.model';
import {Error} from '../../models/error.model';

@Component({
  selector: 'app-import',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    SharedModule,
    TableModule,
    TranslateModule
  ],
  templateUrl: './import.component.html',
  styleUrl: './import.component.scss'
})
export class ImportComponent implements OnInit{

  private items: BehaviorSubject<Error[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Error[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));
  filter: PriceListsFilter = new PriceListsFilter(0, 10, 'id');

  loading = false;
  id: string;
  label: string;

  errors: Error[];

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public dialogService: DialogService,
    private importsService: ImportsService,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    // get importId from URL
    this.id = this.route.snapshot.params.id;
    this.fetchImport();
  }

  fetchImport(): void {
    this.loading = true;
    this.importsService.findById(this.id).subscribe(data => {
      this.pending.next(false);
      this.items.next(data.values);
      this.count.next(data.count);
      this.errors = data.values;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

}
