<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="name" class="required-mark" translate>CUSTOMERS.NAME</label>
      <span class="p-input-icon-left">
            <i class="pi pi-users"></i>
            <input id="name" type="text" pInputText formControlName="name">
        </span>
    </div>
    <div class="field col-12 md:col-4">
      <label for="vat" translate>CUSTOMERS.VAT</label>
      <span class="p-input-icon-left p-input-icon-right">
        <input id="vat" type="text" pInputText formControlName="vat">
      </span>
    </div>
    <div class="field col-12 md:col-2">
      <label for="customerId" translate>CUSTOMERS.CUSTOMER_ID</label>
      <span class="p-input-icon-left p-input-icon-right">
        <input id="customerId" type="text" pInputText formControlName="customerId">
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <label for="nameLong" translate>CUSTOMER.LONG_NAME</label>
      <span class="p-input-icon-left">
              <i class="pi pi-user"></i>
              <input id="nameLong" type="text" pInputText formControlName="nameLong">
            </span>
    </div>
    <div class="field col-12 md:col-4">
      <label for="registrationNumber" translate>CUSTOMERS.REG_NUMBER</label>
      <span class="p-input-icon-left p-input-icon-right">
              <input id="registrationNumber" type="number" pInputText formControlName="registrationNumber">
					  </span>
    </div>
    <div class="field col-12 md:col-2">
      <div class="flex justify-content-end mb-2" translate>CUSTOMER.COMPANY</div>
      <p-inputSwitch formControlName="company" (click)="companySwitch()"
                     class="flex justify-content-end"></p-inputSwitch>

    </div>
    <div formGroupName="address" class="col-12 md:col-6 mt-3">
      <div class="field col-12 md:col-12">
        <label for="street1" class="required-mark" translate>CUSTOMER.STREET1</label>
        <input id="street1" type="text" pInputText formControlName="street1">
      </div>
      <div class="field col-12 md:col-12">
        <label for="street2" translate>CUSTOMER.STREET2</label>
        <input id="street2" type="text" pInputText formControlName="street2">
      </div>
      <div class="field col-12 md:col-12">
        <label for="city" translate>CUSTOMERS.CITY</label>
        <input id="city" type="text" pInputText formControlName="city">
      </div>
      <div class="field col-12 md:col-12">
        <label for="postalCode" class="required-mark" translate>CUSTOMERS.POSTAL_CODE</label>
        <input id="postalCode" type="text" pInputText formControlName="postalCode">
      </div>
      <div class="field col-12 md:col-12">
        <label for="postal" class="required-mark" translate>CUSTOMERS.POSTAL</label>
        <input id="postal" type="text" pInputText formControlName="postal">
      </div>
      <div class="field col-12 md:col-12">
        <label for="stateOrProvince" translate>CUSTOMERS.STATE_OR_PROVINCE</label>
        <input id="stateOrProvince" type="text" pInputText formControlName="stateOrProvince">
      </div>
      <div class="field col-12 md:col-12">
        <label for="country" class="required-mark" translate>CUSTOMERS.COUNTRY</label>
        <p-autoComplete formControlName="country"
                        [suggestions]="filtered"
                        (completeMethod)="filterCodelist($event, 'codelists/countries')"
                        [dropdown]="true"
                        forceSelection="true"
                        appendTo="body"
                        id="country"
                        field="name">
        </p-autoComplete>
      </div>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <div class="field col-12 md:col-12">
        <label for="phone" translate>CUSTOMER.PHONE</label>
        <span class="p-input-icon-left">
                <i class="pi pi-phone"></i>
                <input id="phone" type="text" pInputText formControlName="phone">
              </span>
      </div>
      <div class="field col-12 md:col-12">
        <label for="mobile" translate>CUSTOMER.MOBILE</label>
        <span class="p-input-icon-left">
                <i class="pi pi-phone"></i>
                <input id="mobile" type="text" pInputText formControlName="mobile">
              </span>
      </div>
      <div class="field col-12 md:col-12">
        <label for="email" translate>CUSTOMERS.EMAIL</label>
        <input id="email" type="text" pInputText formControlName="email">
      </div>
      <div class="field col-12 md:col-12">
        <label for="fax" translate>CUSTOMER.FAX</label>
        <span class="p-input-icon-left">
                <i class="pi pi-phone"></i>
                <input id="fax" type="text" pInputText formControlName="fax">
              </span>

      </div>
      <div class="field col-12 md:col-12">
        <label for="url" translate>CUSTOMER.URL</label>
        <input id="url" type="text" pInputText formControlName="url">
      </div>
    </div>
  </div>
</form>
<p-footer>
  <div class="flex justify-content-end p-2">
    <p-button icon="pi pi-check" class="p-button-raised" (click)="save()" [disabled]="form.invalid"
              [label]="'BUTTON.SAVE' | translate"></p-button>
  </div>
</p-footer>
