import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {

  model: any[];
  private languageSubscription: Subscription;

  constructor(
    public appMain: AppMainComponent,
    private translate: TranslateService,
    private keycloak: KeycloakService
  ) {
  }

  ngOnInit() {
    if (this.keycloak.isLoggedIn()) {
      if (this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')) {
        this.model = this.buildAdminMenu();
        this.languageSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.model = this.buildAdminMenu();
          },
        );
      } else if (this.keycloak.isUserInRole('ROLE_USER')) {
        this.model = this.buildUserMenu();
        this.languageSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.model = this.buildUserMenu();
          },
        );
      }
    }

  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  /*  This is the real MENU !!!!  */
  private buildUserMenu(): any[] {
    return [
      {
        label: this.translate.instant('MENU.FAVOURITES'), icon: 'pi pi-home',
        items: [
          {label: this.translate.instant('MENU.HOME'), icon: 'pi pi-fw pi-home', routerLink: ['/']}
        ]
      },
      {separator: true},
      {
        label: this.translate.instant('MENU.SALES'), icon: 'pi pi-fw pi-align-left',
        items: [
          {label: this.translate.instant('MENU.DELIVERY_NOTES'), icon: 'pi pi-fw pi-book', routerLink: ['/deliveryNotes']},
          {label: this.translate.instant('MENU.OFFERS'), icon: 'pi pi-fw pi-id-card', routerLink: ['/offers']},
          {label: this.translate.instant('MENU.ORDERS'), icon: 'pi pi-fw pi-check-square', routerLink: ['/orders']},
          /*{
            label: this.translate.instant('MENU.SALES_OVERVIEW'),
            icon: 'pi pi-external-link',
            routerLink: ['/overview']
          },*/
          {label: this.translate.instant('MENU.PRODUCTS'), icon: 'pi pi-tablet', routerLink: ['/products']},
          {label: this.translate.instant('MENU.ARTICLES'), icon: 'pi pi-tablet', routerLink: ['/articles']},
          {label: this.translate.instant('MENU.PROJECTS'), icon: 'pi pi-tablet', routerLink: ['/projects']},
        ]
      },
      {separator: true},
      {
        label: this.translate.instant('MENU.CUSTOMER_MANAGEMENT'), icon: 'pi pi-fw pi-align-left',
        items: [
          {label: this.translate.instant('MENU.CUSTOMERS'), icon: 'pi pi-user', routerLink: ['/customers']},
          {label: this.translate.instant('MENU.NOTES'), icon: 'pi pi-user', routerLink: ['/notes']},
        ]
      },
    ];
  }

  private buildAdminMenu(): any[] {
    return [
      {
        label: this.translate.instant('MENU.FAVOURITES'), icon: 'pi pi-home',
        items: [
          {label: this.translate.instant('MENU.HOME'), icon: 'pi pi-fw pi-home', routerLink: ['/']},
          {label: this.translate.instant('MENU.SENSORS'), icon: 'pi pi-fw pi-chart-bar', routerLink: ['/sensors']},
        ]
      },
      {separator: true},
      {
        label: this.translate.instant('MENU.SALES'), icon: 'pi pi-fw pi-align-left',
        items: [
          {label: this.translate.instant('MENU.DELIVERY_NOTES'), icon: 'pi pi-fw pi-book', routerLink: ['/deliveryNotes']},
          {label: this.translate.instant('MENU.OFFERS'), icon: 'pi pi-fw pi-id-card', routerLink: ['/offers']},
          {label: this.translate.instant('MENU.ORDERS'), icon: 'pi pi-fw pi-check-square', routerLink: ['/orders']},
          {label: this.translate.instant('MENU.PRODUCTS'), icon: 'pi pi-tablet', routerLink: ['/products']},
          {label: this.translate.instant('MENU.ARTICLES'), icon: 'pi pi-tablet', routerLink: ['/articles']},
          {label: this.translate.instant('MENU.PROJECTS'), icon: 'pi pi-tablet', routerLink: ['/projects']},
        ]
      },
      {separator: true},
      {
        label: this.translate.instant('MENU.CUSTOMER_MANAGEMENT'), icon: 'pi pi-fw pi-align-left',
        items: [
          {label: this.translate.instant('MENU.CUSTOMERS'), icon: 'pi pi-user', routerLink: ['/customers']},
          {label: this.translate.instant('MENU.NOTES'), icon: 'pi pi-user', routerLink: ['/notes']},
          {label: this.translate.instant('MENU.OPPORTUNITIES'), icon: 'pi pi-user', routerLink: ['/opportunities']},
          // {label: this.translate.instant('MENU.UPLOAD'), icon: 'pi pi-upload', routerLink: ['/upload']},
        ]
      },
      {separator: true},
      {
        label: this.translate.instant('MENU.CODE_LISTS'), icon: 'pi pi-fw pi-align-left',
        items: [
          {label: this.translate.instant('MENU.COLORS'), icon: 'pi pi-fw pi-id-card', routerLink: ['/colorcc']},
        ]
      },
      {separator: true},
      {
        label: this.translate.instant('MENU.PRICE_LISTS'), icon: 'pi pi-fw pi-align-left',
        items: [
          {label: this.translate.instant('MENU.PRICE_LISTS'), icon: 'pi pi-fw pi-id-card', routerLink: ['/pricelists']},
          {label: this.translate.instant('MENU.IMPORTS'), icon: 'pi pi-fw pi-id-card', routerLink: ['/imports']},
        ]
      },
    ];
  }
}
