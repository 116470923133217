import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorsFilter, SensorsService } from '../../service/sensors.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Sensor } from '../../models/sensor.model';
import { PricesService } from '../../service/prices.service';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss']
})
export class SensorsComponent implements OnInit {

  private items: BehaviorSubject<Sensor[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Sensor[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  data1: TreeNode[];
  loading = true;
  selectedNode: TreeNode;

  filter: SensorsFilter = new SensorsFilter(0, 10);

  constructor(
    private translate: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private sensorsService: SensorsService,
    public priceService: PricesService,
  ) { }

  ngOnInit(): void {
    this.filter = {...this.filter, ...JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')};
    this.fetch();
    this.data1 = [{
      label: 'Chemcolor',
      type: 'person',
      styleClass: 'p-person',
      expanded: true,
      data: {name: 'Tovarna', avatar: 'walter.jpg'},
      children: [
        {
          label: 'Hala 1',
          type: 'person',
          styleClass: 'p-person',
          expanded: true,
          data: {name: 'Vhod', avatar: 'mike.jpg'},
          children: [{
            label: 'Pozicija 1',
            styleClass: 'department-coo',
            expanded: true,
            children: [{
              label: 'Temp: 23,6°C',
              styleClass: 'department-coo',
            },
              {
                label: 'Vlaga: 84%',
                styleClass: 'department-coo',
              }]
          }]
        },
        {
          label: 'Hala 2',
          type: 'person',
          styleClass: 'p-person',
          expanded: true,
          data: {name: 'Razvoj', avatar: 'saul.jpg'},
          children: [{
            label: 'Pozicija 2',
            styleClass: 'department-cfo',
            expanded: true,
            children: [{
              label: 'Temp: 22,6°C',
              styleClass: 'department-cfo',
            },
              {
                label: 'Vlaga: 86%',
                styleClass: 'department-cfo',
              }]
          },
            {
              label: 'Pozicija 3',
              styleClass: 'department-cfo',
              expanded: true,
              children: [{
                label: 'Temp: 22,8°C',
                styleClass: 'department-cfo',
              },
                {
                  label: 'Vlaga: 87%',
                  styleClass: 'department-cfo',
                }]
            }],
        },
        {
          label: 'Hala 3',
          type: 'person',
          styleClass: 'p-person',
          expanded: true,
          data: {name: 'Izhod', avatar: 'mike.jpg'},
          children: [{
            label: 'Pozicija 4',
            styleClass: 'department-coo',
            expanded: true,
            children: [{
              label: 'Temp: 23,9°C',
              styleClass: 'department-coo',
            },
              {
                label: 'Vlaga: 82%',
                styleClass: 'department-coo',
              }]
          }]
        },
      ]
    }];
  }

  viewSensorData(sensor: Sensor) {
    this.router.navigate(['/sensor/' + sensor.sensorId]);
  }

  fetch(): void {
    this.loading = true;
    this.sensorsService.find(this.filter)
      .subscribe(data => {
          this.loading = false;
          this.items.next(data.values);
        },
        error => {
          console.log(error);
        });
  }
}
