import { Component, OnInit } from '@angular/core';
import { CodelistsService } from '../../service/codelists.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {BehaviorSubject, Observable, take} from 'rxjs';
import { Offer } from '../../models/offer.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {I18nUtilService, Language} from '../../core/util/i18n-util.service';
import {AttachmentsService} from '../../service/attachments.service';
import {AttachmentFullModalComponent} from '../../core/modals/attachment-full-modal/attachment-full-modal.component';
import {DeliveryNotesService} from '../../service/deliveryNotes.service';
import {
  DeliveryNoteFullModalComponent
} from '../../core/modals/delivery-note-full-modal/delivery-note-full-modal.component';
import {DeliveryNote} from '../../models/deliveryNote.model';
import {OffersService} from '../../service/offers.service';
import {SharedAttachmentService} from '../../service/sharedAttachments.service';

@Component({
  selector: 'app-delivery-note',
  templateUrl: './delivery-note.component.html',
  styleUrls: ['./delivery-note.component.scss']
})
export class DeliveryNoteComponent implements OnInit {

  constructor(
    private codelistsService: CodelistsService,
    private deliveryNotesService: DeliveryNotesService,
    private offersService: OffersService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private messageService: MessageService,
    private translate: TranslateService,
    private i18utils: I18nUtilService,
    private attachmentsService: AttachmentsService,
    private sharedAttachmentService: SharedAttachmentService,
    private confirmationService: ConfirmationService,
  ) {
  }

  loading = true;
  id: string;
  offer: Offer;
  copyOffer: Offer;
  showDiscounts = true;
  showVAT = true;
  private deliveryNote: BehaviorSubject<DeliveryNote> = new BehaviorSubject(null);
  deliveryNote$: Observable<DeliveryNote> = this.deliveryNote.asObservable();

  blob: any;
  offerLangs$: Observable<Language[]>;
  selectedLang: Language;
  ref: DynamicDialogRef;

  // Attachments
  uploadedFiles: any[] = [];

  protected readonly Math = Math;

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.fetch(this.id);
    this.fetchAttachments();
    this.offerLangs$ = this.i18utils.languages$;
    this.offerLangs$.pipe(take(1)).subscribe(langs => {
      if (langs && langs.length > 0) {
        this.selectedLang = langs[0];
      }
    });
  }

  fetch(id: string): void {
    this.loading = true;
    this.deliveryNotesService.get(id)
      .subscribe(
        data => {
          this.deliveryNote.next(data);
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  print() {
    window.print();
  }

  openPdf(id: string): void {
    this.deliveryNotesService.getPdf(id, this.selectedLang.code, this.showDiscounts, this.showVAT)
      .subscribe(
        data => {
          this.blob = new Blob([data], {type: 'application/pdf'});

          const downloadURL = window.URL.createObjectURL(data);
          window.open(downloadURL, '_blank');
          URL.revokeObjectURL(downloadURL);
        },
        error => {
          console.log(error);
        });
  }

  savePdf(id: string): void {
    this.deliveryNotesService.getPdf(id, this.selectedLang.code, this.showDiscounts, this.showVAT)
      .subscribe(
        data => {
          this.blob = new Blob([data], {type: 'application/pdf'});

          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = this.deliveryNote.value.number + '.pdf';
          link.click();
        },
        error => {
          console.log(error);
        });
  }

  createOffer(): void {
    this.offer = this.deliveryNote.value;
    this.offer.offersArticles = this.deliveryNote.value.deliveryNotesArticles;
    this.offer.id = null;

    this.offersService.create(this.offer)
      .subscribe(
        response => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created offer: ' + this.offer.number,
          });
          this.router.navigate(['offer/' + response.id]);
        },
        error => {
          console.log(error);
        });
  }

  duplicateOffer(): void {
    this.copyOffer = this.deliveryNote.value;
    this.copyOffer.id = null;
    this.copyOffer.dateCreated = new Date();
    this.copyOffer.dateDelivery = null;

    this.deliveryNotesService.create(this.copyOffer)
      .subscribe(
        response => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created offer: ' + this.copyOffer.number,
          });
          // Reload on the same url ...
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate(['deliveryNote/' + response.id]));
        },
        error => {
          console.log(error);
        });
  }

  openModal(buyer: string, input: DeliveryNote): void {
    this.ref = this.dialogService.open(DeliveryNoteFullModalComponent, {
      header: this.translate.instant('DELIVERY_NOTE.DELIVERY_NOTE'),
      width: '98vw',
      height: '96vh',
      data: {
        buyer,
        deliveryNote: input
      }
    });

    this.ref.onClose.subscribe((deliveryNote: DeliveryNote) => {
      if (deliveryNote) {
        this.fetch(this.id);
      }
    });
  }

  fetchAttachments(): void {
    this.attachmentsService.findById(this.id, 'DELIVERY_NOTES')
      .subscribe(data => {
        this.uploadedFiles = data;
        this.loading = false;
      }, error => {
        console.log(error);
      });
  }

  openModalUpload(id: string, entityType: string): void {
    this.ref = this.dialogService.open(AttachmentFullModalComponent, {
      header: this.translate.instant('ATTACHMENTS.ADD'),
      width: '50vw',
      height: '25vh',
      data: {id, entityType}
    });

    this.ref.onClose.subscribe(() => {
      this.fetchAttachments();
    });
  }

  downloadAttachment(attachment: any) {
    this.sharedAttachmentService.downloadAttachment(attachment);
  }

  deleteAttachment(attachment: any) {
    this.sharedAttachmentService.deleteAttachment(attachment, this.fetchAttachments.bind(this));
  }
}
