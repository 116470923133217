<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>PROJECTS.PROJECTS</h5>
      <p-table #dt
               [value]="projects"
               dataKey="id"
               [rowHover]="true"
               [lazy]="true"
               (onLazyLoad)="pageChanged($event)"
               [rows]="filter.limit"
               [totalRecords]="(count$ | async) || 0"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]"
               [loading]="loading"
               [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0"
               [globalFilterFields]="['name', 'code', 'description']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
              <p-button icon="pi pi-plus" class="p-button-raised p-w-100" (click)="openModal(null)"
                        [label]="'PROJECTS.CREATE_PROJECT' | translate"></p-button>
            </span>
            <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
                </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CODELISTS.NAME
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="description">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CODELISTS.DESCRIPTION
                <p-sortIcon field="description"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="buyer">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PROJECT.BUYER
                <p-sortIcon field="buyer"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="projectType">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PROJECT.TYPE
                <p-sortIcon field="projectType"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="status">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PROJECT.STATUS
                <p-sortIcon field="status"></p-sortIcon>
              </div>
            </th>

            <th pSortableColumn="dateStart">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PROJECT.DATE_STARTED
                <p-sortIcon field="dateStart"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="dateEnd">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PROJECT.DATE_ENDED
                <p-sortIcon field="dateEnd"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-project>
          <tr class="p-row">
            <td (click)="viewProject(project.id)" class="cursor-pointer">{{project.name}}</td>
           <td>{{project.description}}</td>
            <td> {{project.buyer?.name}}</td>
            <td> {{project.projectType?.name}}</td>
            <td> {{project.projectStatus?.name}}</td>
           <td> {{project.dateStart | date: 'dd.MM.yyyy'}}</td>
           <td> {{project.dateEnd | date: 'dd.MM.yyyy'}}</td>
            <td>
              <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                      (click)="openModal(project)"></button>
              <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                      (click)="delete(project)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>PROJECTS.NO_PROJECTS_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
