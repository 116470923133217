import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, Observable, take} from 'rxjs';
import { Offer } from '../../models/offer.model';
import { CodelistsService } from '../../service/codelists.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import { OrdersService } from '../../service/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {I18nUtilService, Language} from '../../core/util/i18n-util.service';
import {AttachmentsService} from '../../service/attachments.service';
import {AttachmentFullModalComponent} from '../../core/modals/attachment-full-modal/attachment-full-modal.component';
import {DeliveryNote} from '../../models/deliveryNote.model';
import {DeliveryNotesService} from '../../service/deliveryNotes.service';
import {OrderFullModalComponent} from '../../core/modals/order-full-modal/order-full-modal.component';
import {SharedAttachmentService} from '../../service/sharedAttachments.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  constructor(
    private codelistsService: CodelistsService,
    private ordersService: OrdersService,
    private deliveryNotesService: DeliveryNotesService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    public dialogService: DialogService,
    private translate: TranslateService,
    private i18utils: I18nUtilService,
    private attachmentsService: AttachmentsService,
    private sharedAttachmentService: SharedAttachmentService,
    private confirmationService: ConfirmationService,
  ) { }

  loading = true;
  id: string;
  deliveryNote: DeliveryNote;
  private order: BehaviorSubject<Offer> = new BehaviorSubject(null);
  order$: Observable<Offer> = this.order.asObservable();

  showDiscounts = true;
  showVAT = true;
  blob: any;
  offerLangs$: Observable<Language[]>;
  selectedLang: Language;

  ref: DynamicDialogRef;

  // Attachments
  uploadedFiles: any[] = [];

  protected readonly Math = Math;

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.fetch(this.id);
    this.fetchAttachments();
    this.offerLangs$ = this.i18utils.languages$;
    this.offerLangs$.pipe(take(1)).subscribe(langs => {
      if (langs && langs.length > 0) {
        this.selectedLang = langs[0];
      }
    });
  }

  fetch(id: string): void {
    this.loading = true;
    this.ordersService.get(id)
      .subscribe(
        data => {
          this.order.next(data);
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  print() {
    window.print();
  }

  openPdf(id: string): void {
    this.ordersService.getPdf(id, this.selectedLang.code, this.showDiscounts, this.showVAT)
      .subscribe(
        data => {
          this.blob = new Blob([data], {type: 'application/pdf'});

          const downloadURL = window.URL.createObjectURL(data);
          window.open(downloadURL, '_blank');
          URL.revokeObjectURL(downloadURL);
        },
        error => {
          console.log(error);
        });
  }

  savePdf(id: string): void {
    this.ordersService.getPdf(id, this.translate.currentLang, this.showDiscounts, this.showVAT)
      .subscribe(
        data => {
          this.blob = new Blob([data], {type: 'application/pdf'});

          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = this.order.value.number + '.pdf';
          link.click();
        },
        error => {
          console.log(error);
        });
  }

  openModal(buyer: string, input: Offer): void {
    this.ref = this.dialogService.open(OrderFullModalComponent, {
      header: this.translate.instant('ORDER.ORDER'),
      width: '98vw',
      height: '96vh',
      data: {
        buyer,
        offer: input
      }
    });

    this.ref.onClose.subscribe((offer: Offer) => {
      if (offer) {
        this.fetch(this.id);
      }
    });
  }

  createDeliveryNote(): void {
    this.deliveryNote = this.order.value;
    this.deliveryNote.deliveryNotesArticles = this.order.value.ordersArticles;
    this.deliveryNote.id = null;

    this.deliveryNotesService.create(this.deliveryNote)
      .subscribe(
        response => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created delivery note: ' + this.deliveryNote.number,
          });
          this.router.navigate(['deliveryNote/' + response.id]);
        },
        error => {
          console.log(error);
        });
  }

  fetchAttachments(): void {
    this.attachmentsService.findById(this.id, 'ORDERS')
      .subscribe(data => {
        this.uploadedFiles = data;
        this.loading = false;
      }, error => {
        console.log(error);
      });
  }

  openModalUpload(id: string, entityType: string): void {
    this.ref = this.dialogService.open(AttachmentFullModalComponent, {
      header: this.translate.instant('ATTACHMENTS.ADD'),
      width: '50vw',
      height: '25vh',
      data: {id, entityType}
    });

    this.ref.onClose.subscribe(() => {
      this.fetchAttachments();
    });
  }

  downloadAttachment(attachment: any) {
    this.sharedAttachmentService.downloadAttachment(attachment);
  }


  deleteAttachment(attachment: any) {
    this.sharedAttachmentService.deleteAttachment(attachment, this.fetchAttachments.bind(this));
  }
}
