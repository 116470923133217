import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../service/products.service';
import { async, BehaviorSubject, Observable } from 'rxjs';
import { Product } from '../../models/product.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ProductFullModalComponent } from '../../core/modals/product-full-modal/product-full-modal.component';
import { Article } from '../../models/article.model';
import { ArticleFullModalComponent } from '../../core/modals/article-full-modal/article-full-modal.component';
import { ArticlesFilter, ArticlesService } from '../../service/articles.service';
import {KeycloakService} from 'keycloak-angular';
import {AttachmentFullModalComponent} from '../../core/modals/attachment-full-modal/attachment-full-modal.component';
import {AttachmentsService} from '../../service/attachments.service';
import { SharedAttachmentService } from 'src/app/service/sharedAttachments.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

  id: number;
  loading = true;

  // Attachments
  uploadedFiles: any[] = [];

  ref: DynamicDialogRef;

  private product: BehaviorSubject<Product> = new BehaviorSubject(null);
  product$: Observable<Product> = this.product.asObservable();

  articles: Article[];
  private itemsArticles: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  itemsArticles$: Observable<Article[]> = this.itemsArticles.asObservable();
  private countArticles: BehaviorSubject<number> = new BehaviorSubject(0);
  articlesFilter: ArticlesFilter = new ArticlesFilter(0, 10, 'nameLong');
  countArticles$: Observable<number> = this.countArticles.asObservable();

  constructor(
    private productsService: ProductsService,
    private articlesService: ArticlesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private translate: TranslateService,
    public keycloak: KeycloakService,
    private attachmentsService: AttachmentsService,
    private sharedAttachmentService: SharedAttachmentService,
  ) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.articlesFilter = {...this.articlesFilter, ...JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')};
    this.fetch(this.id);
    this.fetchAttachments();
  }

  ngOnDestroy(): void {
    this.itemsArticles.complete();
    this.countArticles.complete();
  }

  fetch(id: number): void {
    this.loading = true;
    this.productsService.findById(id)
      .subscribe(
        data => {
          this.product.next(data);
        },
        error => {
          console.log(error);
        });
  }

  fetchArticles(productId: number): void {
    this.loading = true;
    this.articlesService.findByProductId(productId, this.articlesFilter).subscribe(data => {
        this.itemsArticles.next(data.values);
        this.countArticles.next(data.count);
        this.articles = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  openProductModal(input: Product): void {
    this.ref = this.dialogService.open(ProductFullModalComponent, {
      header: this.translate.instant('PRODUCT.PRODUCT'),
      width: '70vw',
      data: {product: input}
    });

    this.ref.onClose.subscribe((product: Product) => {
      if (product) {
        this.fetch(product.id);
        // TODO: check later !!!
        // this.messageService.add({severity: 'info', summary: 'Offer updated', detail: offer.number});
      }
    });
  }

  openArticleModal(input: Article): void {
    if (input == null) {
      input = new Article();
      input.product = new Product();
      this.product$.subscribe(product => input.product = product);
    }
    this.ref = this.dialogService.open(ArticleFullModalComponent, {
      header: this.translate.instant('ARTICLE.ARTICLE'),
      width: '70vw',
      data: {article: input}
    });

    this.ref.onClose.subscribe((article: Article) => {
      if (article) {
        this.fetchArticles(article.product.id);
        // TODO: check later !!!
        // this.messageService.add({severity: 'info', summary: 'Offer updated', detail: offer.number});
      }
    });
  }

  deleteArticle(article: Article): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + article.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.articlesService.delete(article.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted article: ' + article.name,
              });
              this.fetchArticles(article.product.id);
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.articlesFilter.limit = event.rows;
    this.articlesFilter.offset = event.first;
    this.articlesFilter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.articlesFilter.orderBy = '-' + event.sortField;
    } else {
      this.articlesFilter.orderBy = event.sortField;
    }
    this.fetchArticles(this.product.value.id);
  }

  protected readonly Math = Math;

  fetchAttachments(): void {
    this.attachmentsService.findById(this.id, 'PRODUCTS')
      .subscribe(data => {
        this.uploadedFiles = data;
        this.loading = false;
      }, error => {
        console.log(error);
      });
  }

  openModalUpload(id: string, entityType: string): void {
    this.ref = this.dialogService.open(AttachmentFullModalComponent, {
      header: this.translate.instant('ATTACHMENTS.ADD'),
      width: '50vw',
      height: '25vh',
      data: {id, entityType}
    });

    this.ref.onClose.subscribe(() => {
      this.fetchAttachments();
    });
  }

  downloadAttachment(attachment: any) {
    this.sharedAttachmentService.downloadAttachment(attachment);
  }

  deleteAttachment(attachment: any) {
    this.sharedAttachmentService.deleteAttachment(attachment, this.fetchAttachments.bind(this));
  }
}
