import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {NotesFilter, NotesService} from '../../service/notes.service';
import {Note} from '../../models/note.model';
import {NoteFullModalComponent} from '../../core/modals/note-full-modal/note-full-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Note[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  loading = true;
  customEvents: any[];
  notes: any[];
  filter: NotesFilter = new NotesFilter(0, 10, '-createdDate');
  ref: DynamicDialogRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notesService: NotesService,
    private translate: TranslateService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.items.complete();
    this.count.complete();
    this.pending.complete();
  }

  fetchNotes(): void {
    this.loading = true;
    this.notesService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.notes = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetchNotes();
  }
  openNoteModal(note: Note): void {
    this.ref = this.dialogService.open(NoteFullModalComponent, {
      header: this.translate.instant('NOTES.NOTE'),
      width: '98vw',
      maximizable: true,
      data: {
        note
      }
    });

    // tslint:disable-next-line:no-shadowed-variable
    this.ref.onClose.subscribe((note: Note) => {
      if (note) {
        this.fetchNotes();
      }
    });
  }

  deleteNote(note: Note): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete note ' + note.title + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notesService.delete(note.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('SUCCESS.SUCCESS'),
                detail: this.translate.instant('SUCCESS.DELETED.NOTE') + ' ' + note.title,
              });
              this.fetchNotes();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  addOffer(): void {
    this.router.navigate(['/offers'], { queryParams: { create: '1'}});
  }

  addOrder(): void {
    this.router.navigate(['/orders'], { queryParams: { create: '1'}});
  }

  addCustomer(): void {
    this.router.navigate(['/customers'], { queryParams: { create: '1'}});
  }

  addOpportunity(): void {
    this.router.navigate(['/opportunities'], { queryParams: { create: '1'}});
  }

  showMore(): void {
    this.router.navigate(['/notes']);
  }
}
