import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

import { AddressPipe } from './pipes/address.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { NiceNumberPipe } from './pipes/nice-number.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DragDropModule,
  ],
  declarations: [
    // pipes
    AddressPipe,
    EmptyPipe,
    NiceNumberPipe
  ],
  exports: [
    // modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DragDropModule,
    // pipes
    AddressPipe,
    EmptyPipe,
    NiceNumberPipe
  ]
})
export class SharedModule {
}
