<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>CODELISTS.CODELISTS_COLORS</h5>
      <p-table #dt [value]="codelists" dataKey="id"
               [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0" [globalFilterFields]="['name', 'code', 'description']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
              <p-button icon="pi pi-plus" class="p-button-raised p-w-100" (click)="openModal(null)"
                        [label]="'BUTTON.ADD' | translate"></p-button>
            </span>
            <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
                </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CODELISTS.NAME
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="code">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CODELISTS.CODE
                <p-sortIcon field="code"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="description">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CODELISTS.DESCRIPTION
                <p-sortIcon field="description"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-codelist>
          <tr class="p-row">
            <td>
              {{codelist.name}}
            </td>
            <td>
              {{codelist.code}}
            </td>
            <td>
              {{codelist.description}}
            </td>
            <td>
              <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                      (click)="openModal(codelist)"></button>
              <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                      (click)="delete(codelist)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>CODELISTS.NO_CODELISTS_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
