import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Contact, Customer} from '../../../models/customer.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {DialogModule} from 'primeng/dialog';
import {ContactsService} from '../../../service/contacts.service';
import {CodelistsService} from '../../../service/codelists.service';
import {emailValidator} from '../../forms/validators/email-validator';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-contact-full-modal',
  templateUrl: './contact-full-modal.component.html',
  styleUrls: ['./contact-full-modal.component.scss']
})
export class ContactFullModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  filtered: any;
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private contactTypes: BehaviorSubject<any[]> = new BehaviorSubject([]);
  contactTypes$: Observable<any[]> = this.contactTypes.asObservable();
  id: number;

  constructor(
    private contactsService: ContactsService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private modal: DialogModule,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private codelistsService: CodelistsService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      title: fb.control(null),
      firstName: fb.control(null, Validators.required),
      lastName: fb.control(null, Validators.required),
      phone: fb.control(null),
      mobile: fb.control(null),
      email: fb.control(null, [emailValidator, Validators.required]),
      comment: fb.control(null),
      customer: fb.control(null),
      position: fb.control(null),
      contactTypes: fb.control(null),
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      if (this.config.data.customer) {
        this.form.get('customer').setValue(this.config.data.customer);
      }
      if (this.config.data.contact) {
        this.form.patchValue(this.config.data.contact);
      }
    }
    this.fetchContactTypes();
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  fetchContactTypes(): void {
    // a request to a remote url with the query and return filtered results
    this.codelistsService.getAll('contactTypes')
      .subscribe(
        data => {
          this.contactTypes.next(data);
        },
        error => {
          console.log(error);
        });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const contact = {...this.form.getRawValue()};
    if (contact.id) {
      this.contactsService.update(contact.id, contact)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated contact: ' + contact.firstName + ' ' + contact.lastName,
            });
            this.ref.close(contact);
          },
          error => {
            console.log(error);
          });
    } else {
      this.contactsService.create(contact)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created contact: ' + contact.firstName + ' ' + contact.lastName,
            });
            this.ref.close(contact);
          },
          error => {
            console.log(error);
          });
    }
  }

  companySwitch(): void {
    if (this.form.get('company').value) {
      this.form.get('vat').enable();
    } else {
      this.form.get('vat').reset();
      this.form.get('vat').disable();
    }
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

}
