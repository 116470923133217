<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>ARTICLES.ARTICLES</h5>
      <p-table #dt
               [value]="articles"
               [lazy]="true"
               (onLazyLoad)="pageChanged($event)"
               [(selection)]="selectedArticles"
               dataKey="id"
               [rowHover]="true"
               [rows]="filter.limit"
               [totalRecords]="(count$ | async) || 0"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,15,20,50,200]"
               [loading]="loading"
               [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0"
               [globalFilterFields]="['nameLong', 'gloss']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
              <!--  CANNOT CREATE ARTICLE WITHOUT PRODUCT !!!   -->
              <p-button icon="pi pi-plus" class="p-button-raised p-w-100" (click)="openOfferModal(selectedArticles)"
                        [disabled]="!selectedArticles || !selectedArticles.length"
                        [label]="'OFFERS.CREATE_OFFER' | translate"></p-button>
            </span>
            <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
                </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="nameLong" style="width: 35%">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                ARTICLES.NAME
                <p-sortIcon field="nameLong"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="ean">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                ARTICLE.EAN
                <p-sortIcon field="ean"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="pantheonId">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                ARTICLE.IDENT
                <p-sortIcon field="pantheonId"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="colorCc">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRODUCTS.COLOR_CC
                <p-sortIcon field="colorCc"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="colorRal">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRODUCTS.COLOR_RAL
                <p-sortIcon field="colorRal"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="colorNcs">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRODUCTS.COLOR_NCS
                <p-sortIcon field="colorNcs"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="gloss">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRODUCTS.GLOSS
                <p-sortIcon field="gloss"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="priceGross">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                ARTICLE.PRICE_RETAIL
                <p-sortIcon field="priceGross"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-article>
          <tr>
            <td>
              <p-tableCheckbox [value]="article"></p-tableCheckbox>
            </td>
            <td>
              {{article.nameLong}}
            </td>
            <td>
              {{article.ean}}
            </td>
            <td>
              {{article.pantheonId}}
            </td>
            <td>
              {{article.product.colorCc?.name}}
            </td>
            <td>
              {{article.product.colorRal?.code}}
            </td>
            <td>
              {{article.product.colorNcs?.name}}
            </td>
            <td>
              {{article.gloss?.name}}
            </td>
            <td>
              {{article.priceGross}}
            </td>
            <td>
              <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                      *ngIf="this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')"
                      (click)="openModal(article)"></button>
              <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                      *ngIf="this.keycloak.isUserInRole('ROLE_ADMINISTRATOR')"
                      (click)="delete(article)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>ARTICLES.NO_ARTICLES_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
