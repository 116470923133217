import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {CodelistsService} from '../../../service/codelists.service';
import {CustomersService} from '../../../service/customers.service';
import {PricesService} from '../../../service/prices.service';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {Article} from '../../../models/article.model';
import {Opportunity} from '../../../models/opportunity.model';
import {OpportunitiesService} from '../../../service/opportunities.service';
import {ProductsFilter, ProductsService} from '../../../service/products.service';
import {Customer} from '../../../models/customer.model';

@Component({
  selector: 'app-opportunity-full-modal',
  templateUrl: './opportunity-full-modal.component.html',
  styleUrls: ['./opportunity-full-modal.component.scss']
})
export class OpportunityFullModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  filtered: any;
  filter: ProductsFilter = new ProductsFilter(0, 10, 'name');
  @Input() opportunity: Opportunity;
  @Input() customer: Customer;
  cutomersForm: UntypedFormGroup;
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private items: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  items$: Observable<Article[]> = this.items.asObservable();
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  count$: Observable<number> = this.count.asObservable();
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);
  pending$: Observable<boolean> = this.pending.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    private customersService: CustomersService,
    private productsService: ProductsService,
    private opportunitiesService: OpportunitiesService,
    public pricesService: PricesService,
    private modal: DialogModule,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      customer: fb.control(null, Validators.required),
      product: fb.control(null),
      description: fb.control(null),
      value: fb.control(null),
      valueUnit: fb.control(null),
      volume: fb.control(null),
      volumeUnit: fb.control(null),
      productGroup: fb.control(null, Validators.required),
      periodOfPotentialDeal: fb.control(new Date(), Validators.required),
      frequency: fb.control(null),
      probability: fb.control(null),
      status: fb.control(null),
      stage: fb.control(null),
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      if (this.config.data.opportunity) {
        this.opportunity = this.config.data.opportunity;
      }
      if (this.config.data.customer) {
        this.fetchCustomer(this.config.data.customer);
      }
    }
    if (this.opportunity && this.opportunity.id) {
      this.form.patchValue(this.opportunity);

      // Create new date from existing opportunity to get rid of error
      this.form.get('periodOfPotentialDeal').setValue(new Date(this.opportunity.periodOfPotentialDeal));
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  filterCustomers(event): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.customersService.findByName(query)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  filterProducts(event, productGroupId): void {
    let filtered: any[] = [];
    this.filter.name = event.query;
    this.filter.productGroupId = productGroupId;
    // a request to a remote url with the query and return filtered results
    this.productsService.find(this.filter)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data.values;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  clearProducts(): void {
    this.form.get('product').setValue(null);
  }

  fetchCustomer(id: string): void {
    this.customersService.get(id)
      .subscribe(
        data => {
          this.form.get('customer').setValue(data);
        },
        error => {
          console.log(error);
        });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const opportunity = {...this.opportunity, ...this.form.getRawValue()};
    if (opportunity.id) {
      this.opportunitiesService.update(opportunity.id, opportunity)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated opportunity',
            });
            this.ref.close(opportunity);
          },
          error => {
            console.log(error);
          });
    } else {
      this.opportunitiesService.create(opportunity)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created opportunity',
            });
            this.ref.close(opportunity);
          },
          error => {
            console.log(error);
          });
    }
  }
}
