import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DatePipe, NgStyle} from '@angular/common';
import {FileUploadModule} from 'primeng/fileupload';
import {ConfirmationService, MessageService, SharedModule} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PriceListsFilter} from '../service/pricelists.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from 'primeng/dynamicdialog';
import {AuthService} from '../service/auth.service';
import {ImportsService} from '../service/imports.service';
import {Import} from '../models/import.model';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-imports',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    DatePipe,
    FileUploadModule,
    SharedModule,
    TableModule,
    TranslateModule,
    NgStyle
  ],
  templateUrl: './imports.component.html',
  styleUrl: './imports.component.scss'
})
export class ImportsComponent  implements OnInit {

  private items: BehaviorSubject<Import[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Import[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));
  filter: PriceListsFilter = new PriceListsFilter(0, 10, 'id');

  loading = false;
  id: string;
  label: string;

  imports: Import[];
  COMPLETED = 'COMPLETED';

  // Attachments
  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef; files  = [];
  uploadUrl = ``;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public dialogService: DialogService,
    private importsService: ImportsService,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.uploadUrl = `${environment.apiUrl}/pricelists/upload`;
    this.fetchImports();
  }

  fetchImports(): void {
    this.loading = true;
    this.importsService.find(this.filter).subscribe(data => {
      this.pending.next(false);
      this.items.next(data.values);
      this.count.next(data.count);
      this.imports = data.values;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

  viewImport(id): void {
    this.router.navigate(['imports/' + id]);
  }

  onUploadAttachment(): void {
    this.fetchImports();
  }
}
