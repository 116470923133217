import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Offer } from '../models/offer.model';
import {BaseApi, ListData, StandardFilter} from '../core/api/base-api';

export class OrdersFilter extends StandardFilter {
  search?: string;
}

@Injectable()
export class OrdersService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAll(): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.apiUrl}/orders`);
  }

  find(filter: OrdersFilter): Observable<ListData<Offer>> {
    return this.list<Offer>(`${environment.apiUrl}/orders`, { params: this.params(filter) });
  }

  get(id: any): Observable<Offer> {
    return this.http.get<Offer>(`${environment.apiUrl}/orders/${id}`);
  }

  getPdf(id: any, lang: any, discounts: any, showVAT: any): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/orders/${id}/pdf?lang=${lang}&discounts=${discounts}&showVAT=${showVAT}`, {responseType: 'blob'});
  }

  getAllForBuyer(buyerId: any): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.apiUrl}/orders/buyer/${buyerId}`);
  }

  getAllForProject(projectId: any): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.apiUrl}/orders/project/${projectId}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/orders`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/orders/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/orders/${id}`);
  }
}
