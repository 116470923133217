import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const emailValidator: ValidatorFn =
  Validators.compose([Validators.email]);

export const emailsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let error = null;
  (control.value || []).forEach(email => {
    if (!EMAIL_REGEXP.test(email)) {
      error = {email: true};
    }
  });
  return error;
};
