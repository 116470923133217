import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { Offer } from '../../models/offer.model';
import { PricesService } from '../../service/prices.service';

@Component({
  selector: 'app-offer-view',
  templateUrl: './offer-view.component.html',
  styleUrls: ['./offer-view.component.scss']
})
export class OfferViewComponent implements OnInit {

  @Input() offer: Offer;
  @Input() showDiscounts = false;
  @Input() showVAT = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public pricesService: PricesService
  ) {
    this.breadcrumbService.setItems([
      {label: 'Offer'}
    ]);
  }

  print() {
    window.print();
  }

  ngOnInit(): void {
    // this.offer.dateCreated = new Date(this.offer.dateCreated + 'Z');
  }
}
