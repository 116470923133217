import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CodelistsService } from '../../../service/codelists.service';
import { Article } from '../../../models/article.model';
import { ArticlesService } from '../../../service/articles.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-article-full-modal',
  templateUrl: './article-full-modal.component.html',
  styleUrls: ['./article-full-modal.component.scss']
})
export class ArticleFullModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup;

  filtered: any;

  @Input() article: Article;

  constructor(
    private articlesService: ArticlesService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    private modal: DialogModule,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      name: fb.control(null, Validators.required),
      pantheon: fb.control(null),
      code: fb.control(null),
      description: fb.control(null),
      density: fb.control(null),
      weight: fb.control(null),
      length: fb.control(null),
      width: fb.control(null),
      height: fb.control(null),
      mass: fb.control(null),
      massUnit: fb.control(null),
      volume: fb.control(null),
      volumeUnit: fb.control(null),
      colorCc: fb.control(null),
      colorNcs: fb.control(null),
      colorRal: fb.control(null),
      gloss: fb.control(null),
      type: fb.control(null),
      recipe: fb.control(null),
      category: fb.control(null),
      unit: fb.control(null),
      packing: fb.control(null),
      priceNet: fb.control(null),
      priceGross: fb.control(null),
      priceTotal: fb.control(null),
      ean: fb.control(null),
      ident: fb.control(null),
      accountingClassification: fb.control(null),
      project: fb.control(null),
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.article = this.config.data.article;
    }
    if (this.article && this.article.id) {
      this.form.patchValue(this.article);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const article = {...this.article, ...this.form.getRawValue()};
    if (article.id) {
      this.articlesService.update(article.id, article)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated article: ' + article.name,
            });
            this.ref.close(article);
          },
          error => {
            console.log(error);
          });
    } else {
      this.articlesService.create(article)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created article: ' + article.name,
            });
            this.ref.close(article);
          },
          error => {
            console.log(error);
          });
    }
  }
}
