import { Component, OnInit } from '@angular/core';
import { CodelistsService } from '../../service/codelists.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import { OffersService } from '../../service/offers.service';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { Offer } from '../../models/offer.model';
import { OrdersService } from '../../service/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { OfferFullModalComponent } from '../../core/modals/offer-full-modal/offer-full-modal.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {I18nUtilService, Language} from '../../core/util/i18n-util.service';
import {AttachmentsService} from '../../service/attachments.service';
import {AttachmentFullModalComponent} from '../../core/modals/attachment-full-modal/attachment-full-modal.component';
import {SharedAttachmentService} from '../../service/sharedAttachments.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  constructor(
    private codelistsService: CodelistsService,
    private offersService: OffersService,
    private ordersService: OrdersService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private messageService: MessageService,
    private translate: TranslateService,
    private i18utils: I18nUtilService,
    private attachmentsService: AttachmentsService,
    private sharedAttachmentService: SharedAttachmentService,
    private confirmationService: ConfirmationService,
  ) {
  }

  loading = true;
  id: string;
  order: Offer;
  copyOffer: Offer;
  showDiscounts = true;
  showVAT = true;
  private offer: BehaviorSubject<Offer> = new BehaviorSubject(null);
  offer$: Observable<Offer> = this.offer.asObservable();

  blob: any;
  offerLangs$: Observable<Language[]>;
  selectedLang: Language;
  ref: DynamicDialogRef;

  // Attachments
  uploadedFiles: any[] = [];

  protected readonly Math = Math;

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.fetch(this.id);
    this.fetchAttachments();
    this.offerLangs$ = this.i18utils.languages$;
    this.offerLangs$.pipe(take(1)).subscribe(langs => {
      if (langs && langs.length > 0) {
        this.selectedLang = langs[0];
      }
    });
  }

  fetch(id: string): void {
    this.loading = true;
    this.offersService.get(id)
      .subscribe(
        data => {
          this.offer.next(data);
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  print() {
    window.print();
  }

  openPdf(id: string): void {
    this.offersService.getPdf(id, this.selectedLang.code, this.showDiscounts, this.showVAT)
      .subscribe(
        data => {
          this.blob = new Blob([data], {type: 'application/pdf'});

          const downloadURL = window.URL.createObjectURL(data);
          window.open(downloadURL, '_blank');
          URL.revokeObjectURL(downloadURL);
        },
        error => {
          console.log(error);
        });
  }

  savePdf(id: string): void {
    this.offersService.getPdf(id, this.selectedLang.code, this.showDiscounts, this.showVAT)
      .subscribe(
        data => {
          this.blob = new Blob([data], {type: 'application/pdf'});

          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = this.offer.value.number + '.pdf';
          link.click();
        },
        error => {
          console.log(error);
        });
  }

  createOrder(): void {
    this.order = this.offer.value;
    this.order.ordersArticles = this.order.offersArticles;
    this.order.id = null;

    this.ordersService.create(this.order)
      .subscribe(
        response => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created order: ' + this.order.number,
          });
          this.router.navigate(['order/' + response.id]);
        },
        error => {
          console.log(error);
        });
  }

  duplicateOffer(): void {
    this.copyOffer = this.offer.value;
    this.copyOffer.id = null;
    this.copyOffer.dateCreated = new Date();
    this.copyOffer.dateDelivery = null;

    this.offersService.create(this.copyOffer)
      .subscribe(
        response => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created offer: ' + this.copyOffer.number,
          });
          // Reload on the same url ...
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate(['offer/' + response.id]));
        },
        error => {
          console.log(error);
        });
  }

  openModal(buyer: string, input: Offer): void {
    this.ref = this.dialogService.open(OfferFullModalComponent, {
      header: this.translate.instant('OFFER.OFFER'),
      width: '98vw',
      height: '96vh',
      data: {
        buyer,
        offer: input
      }
    });

    this.ref.onClose.subscribe((offer: Offer) => {
      if (offer) {
        this.fetch(this.id);
      }
    });
  }

  fetchAttachments(): void {
    this.attachmentsService.findById(this.id, 'OFFERS')
      .subscribe(data => {
        this.uploadedFiles = data;
        this.loading = false;
      }, error => {
        console.log(error);
      });
  }

  openModalUpload(id: string, entityType: string): void {
    this.ref = this.dialogService.open(AttachmentFullModalComponent, {
      header: this.translate.instant('ATTACHMENTS.ADD'),
      width: '50vw',
      height: '25vh',
      data: {id, entityType}
    });

    this.ref.onClose.subscribe(() => {
      this.fetchAttachments();
    });
  }

  downloadAttachment(attachment: any) {
    this.sharedAttachmentService.downloadAttachment(attachment);
  }

  deleteAttachment(attachment: any) {
    this.sharedAttachmentService.deleteAttachment(attachment, this.fetchAttachments.bind(this));
  }
}
