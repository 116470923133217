import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact, Customer } from '../models/customer.model';
import {BaseApi, ListData, StandardFilter} from '../core/api/base-api';

export class ContactsFilter extends StandardFilter {
  search?: string;
  customerId?: number;
}

@Injectable()
export class ContactsService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  listContactsByCustomerId(id: any, filter: ContactsFilter): Observable<ListData<Contact>> {
    return this.list<Contact>(`${environment.apiUrl}/contacts?customerId=${id}`, { params: this.params(filter) });
  }

  listBusinessUnitsByCustomerId(id: any): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${environment.apiUrl}/customers/${id}/businessUnits`);
  }

  findById(id: any): Observable<Contact> {
    return this.http.get(`${environment.apiUrl}/contacts/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/contacts`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/contacts/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/contacts/${id}`);
  }
}
