<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-9">
      <label for="name" class="required-mark" translate>ARTICLE.NAME</label>
      <input id="name" type="text" pInputText formControlName="name">
    </div>
    <div class="field col-12 md:col-3">
      <label for="code" translate>ARTICLE.CODE</label>
      <input id="code" type="text" pInputText formControlName="code">
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-9">
      <label for="description" translate>ARTICLE.DESCRIPTION</label>
      <textarea id="description" type="text" pInputText formControlName="description"></textarea>
    </div>
    <div class="field col-12 md:col-3">
      <label for="pantheon" translate>ARTICLE.PANTHEON</label>
      <input id="pantheon" type="text" pInputText formControlName="pantheon">
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-3">
      <label for="density" translate>ARTICLE.DENSITY</label>
      <input id="density" type="text" pInputText formControlName="density">
    </div>
    <div class="field col-12 md:col-3">
      <label for="weight" translate>ARTICLE.WEIGHT</label>
      <input id="weight" type="text" pInputText formControlName="weight">
    </div>
    <div class="field col-12 md:col-3">
      <label for="height" translate>ARTICLE.HEIGHT</label>
      <input id="height" type="text" pInputText formControlName="height">
    </div>
    <div class="field col-12 md:col-3">
      <label for="width" translate>ARTICLE.WIDTH</label>
      <input id="width" type="text" pInputText formControlName="width">
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-3">
      <label for="ean" translate>ARTICLE.EAN</label>
      <input id="ean" type="text" pInputText formControlName="ean">
    </div>
    <div class="field col-12 md:col-3">
      <label for="ident" translate>ARTICLE.IDENT</label>
      <input id="ident" type="text" pInputText formControlName="ident">
    </div>
    <div class="field col-12 md:col-2">
      <label for="priceNet" translate>ARTICLE.PRICE_COST</label>
      <input id="priceNet" type="text" pInputText formControlName="priceNet">
    </div>
    <div class="field col-12 md:col-2">
      <label for="priceGross" translate>ARTICLE.PRICE_RETAIL</label>
      <input id="priceGross" type="text" pInputText formControlName="priceGross">
    </div>
    <div class="field col-12 md:col-2">
      <label for="priceTotal" translate>ARTICLE.PRICE_WHOLESOME</label>
      <input id="priceTotal" type="text" pInputText formControlName="priceTotal">
    </div>
  </div>
  <!--<div class="p-fluid formgrid grid col-12">
    <div class="field col-12 md:col-4">
      <label for="colorCc" translate>ARTICLE.COLORCC</label>
      <p-autoComplete formControlName="colorCc"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/colorsCc')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="colorCc"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="colorNcs" translate>ARTICLE.COLORNCS</label>
      <p-autoComplete formControlName="colorNcs"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/colorsNcs')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="colorNcs"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="colorRal" translate>ARTICLE.COLORRAL</label>
      <p-autoComplete formControlName="colorRal"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/colorsRal')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="colorRal"
                      field="code">
      </p-autoComplete>
    </div>
  </div>-->
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-4">
      <label for="gloss" translate>ARTICLE.GLOSS</label>
      <p-autoComplete formControlName="gloss"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/glosses')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="gloss"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="type" translate>ARTICLE.TYPE</label>
      <p-autoComplete formControlName="type"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/types')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="type"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-2">
      <label for="volume" translate>ARTICLE.VOLUME</label>
      <input id="volume" type="text" pInputText formControlName="volume">
    </div>
    <div class="field col-12 md:col-2">
      <label for="volumeUnit" translate>ARTICLE.VOLUME_UNIT</label>
      <p-autoComplete formControlName="volumeUnit"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/units')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="volumeUnit"
                      field="name">
      </p-autoComplete>
    </div>
  </div>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-4">
      <label for="category" translate>ARTICLE.CATEGORY</label>
      <p-autoComplete formControlName="category"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/categories')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="category"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-4">
      <label for="accountingClassification" translate>ARTICLE.ACCOUNT_CLASSIFICATION</label>
      <input id="accountingClassification" type="text" pInputText formControlName="accountingClassification">
    </div>
    <div class="field col-12 md:col-2">
      <label for="mass" translate>ARTICLE.MASS</label>
      <input id="mass" type="text" pInputText formControlName="mass">
    </div>
    <div class="field col-12 md:col-2">
      <label for="massUnit" translate>ARTICLE.MASS_UNIT</label>
      <p-autoComplete formControlName="massUnit"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/units')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="massUnit"
                      field="name">
      </p-autoComplete>
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" styleClass="p-button-success" (click)="save()"
    [disabled]="form.invalid"
    [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
