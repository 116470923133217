import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApi, ListData, StandardFilter } from '../core/api/base-api';
import { Project } from '../models/project.model';

export class ProjectsFilter extends StandardFilter {
  search?: string;
}

@Injectable()
export class ProjectsService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: ProjectsFilter): Observable<ListData<Project>> {
    return this.list<Project>(`${environment.apiUrl}/projects`, { params: this.params(filter) });
  }

  findById(id: number): Observable<Project> {
    return this.http.get<Project>(`${environment.apiUrl}/projects/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/projects`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/projects/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/projects/${id}`);
  }

  findByName(name: any): Observable<Project[]> {
    return this.http.get<Project[]>(`${environment.apiUrl}/projects?name=${name}`);
  }
}
