import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '../../../models/project.model';
import { ProjectsService } from '../../../service/projects.service';
import { CustomersService } from '../../../service/customers.service';
import { CodelistsService } from '../../../service/codelists.service';

@Component({
  selector: 'app-project-full-modal',
  templateUrl: './project-full-modal.component.html',
  styleUrls: ['./project-full-modal.component.scss']
})
export class ProjectFullModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup;
  buyersForm: UntypedFormGroup;

  filtered: any;
  private project: Project;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private projectsService: ProjectsService,
    private codelistsService: CodelistsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    private customersService: CustomersService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      name: fb.control(null, Validators.required),
      description: fb.control(null),
      buyer: this.buyersForm,
      dateStart: fb.control(new Date(), Validators.required),
      dateEnd: fb.control(new Date(), Validators.required),
      projectType: fb.control(null),
      projectStatus: fb.control(null),
    });
  }
  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.project = this.config.data.project;
    }
    if (this.project && this.project.id) {
      this.form.patchValue(this.project);
      // Create new date from existing offer to get rid of error
      this.form.get('dateStart').setValue(new Date(this.project.dateStart));
      this.form.get('dateEnd').setValue(new Date(this.project.dateEnd));
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const project = {...this.project, ...this.form.getRawValue()};

    if (project.id) {
      this.projectsService.update(project.id, project)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated project: ' + project.name,
            });
            this.ref.close(project);
          },
          error => {
            console.log(error);
          });
    } else {
      this.projectsService.create(project)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created project: ' + project.name,
            });
            this.ref.close(project);
          },
          error => {
            console.log(error);
          });
    }
  }

  filterCustomers(event): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.customersService.findByName(query)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  selectBuyer(project: Project): void {
    // TODO: Do sth when selecting buyer
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

}
