import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../models/customer.model';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  static transform(address: Address, html: boolean = false): string {
    if (!address) {
      return '';
    }
    const postal = ((address.postalCode || '') + ' ' + (address.city || '')).trim() || '/';
    if (!address.street1) {
      if (postal === '/') {
        return `${address.country || '/'}`;
      }
      return postal;
    }
    if (html) {
      return `${address.street1 || '/'}<br/><small>${postal}</small>`;
    }
    return `${address.street1 || '/'}, ${postal}`;
  }

  transform(address: Address, html: boolean = false): string {
    return AddressPipe.transform(address, html);
  }
}
