<div class="grid" *ngIf="project$|async as project">
  <div class="col-8">
    <h3>{{project.name|empty}}</h3>
  </div>
  <div class="col-4 flex justify-content-end mb-2">
    <p-button type="button" class="p-button-secondary" icon="pi pi-tablet"
              [label]="'PROJECT.EDIT.TITLE' | translate"
              (click)="openProjectModal(project)"
    ></p-button>
  </div>

  <!--    region PRODUCT TABS  -->
  <div class="col-12">
    <div class="card">
      <p-tabView orientation="left">
        <p-tabPanel header="{{'CONTACT.DETAILS' | translate}}" leftIcon="pi pi-user">
          <div class="p-fluid formgrid grid">
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PROJECT.NAME</p></div>
            <div class="col-12 md:col-6"><p>{{ project.name | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PROJECT.DESCRIPTION</p></div>
            <div class="col-12 md:col-6"><p>{{ project.description | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PROJECT.BUYER</p></div>
            <div class="col-12 md:col-6"><p>{{ project.buyer.name | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PROJECT.TYPE</p></div>
            <div class="col-12 md:col-6"><p>{{ project.projectType.name | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PROJECT.STATUS</p></div>
            <div class="col-12 md:col-6"><p>{{ project.projectStatus.name | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PROJECT.DATE_STARTED</p></div>
            <div class="col-12 md:col-6"><p>{{ project.dateStart | date: 'dd.MM.yyyy' | empty }}</p></div>
            <div class="col-12 md:col-6"><p class="p-text-bold" translate>PROJECT.DATE_ENDED</p></div>
            <div class="col-12 md:col-6"><p>{{ project.dateEnd | date: 'dd.MM.yyyy' | empty }}</p></div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.OFFERS' | translate}}" leftIcon="pi pi-id-card">
          <p-table #dt [value]="offers" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['offer.number', 'dateCreated', 'buyer.name']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                            (click)="openOfferModal(project.id.toString(), null)"
                            [label]="'OFFERS.CREATE_OFFER' | translate"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="number">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.NUMBER
                    <p-sortIcon field="number"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="buyer.name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.CUSTOMER
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="priceGross">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    OFFERS.VALUE
                    <p-sortIcon field="priceGross"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-offer>
              <tr class="p-row">
                <td (click)="viewOffer(offer.id)" style="cursor: pointer;">
                  {{offer.number}}
                </td>
                <td>
                  {{offer.buyer?.name}}
                </td>
                <td>
                  {{offer.priceTotalGross}} €
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                          (click)="openOfferModal(null, offer)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteOffer(offer)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>OFFERS.NO_OFFERS_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.CHECKLISTS' | translate}}" leftIcon="pi pi-check-square">
          <p-table #dt [value]="forms" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['title', 'description']">
            <!--<ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                            (click)="openChecklistModal()"
                            [label]="'CHECKLIST.ADD_CHECKLIST' | translate"></p-button>
                </span>
              </div>
            </ng-template>-->
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="title">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    CUSTOMER.ADDRESS
                    <p-sortIcon field="title"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-form>
              <tr class="p-row">
                <td>{{ form.title }}</td>
                <td style="text-align: right">
                  <!--<p-button type="button" class="p-button-secondary" icon="pi pi-user-edit"
                          (click)="editContactModal(contact, $event)"></p-button>-->
                  <button pButton type=button class="p-button-secondary table-button mr-2" icon="pi pi-user-edit"
                          (click)="openChecklistModal(form)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>CONTACT.NO_CONTACTS_FOUND</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{'MENU.ATTACHMENTS' | translate}}" leftIcon="pi pi-upload">
          <p-table #dt [value]="uploadedFiles" dataKey="id"
                   [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                   [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                   currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                   [filterDelay]="0" [globalFilterFields]="['attachment.name', 'attachment.size']">
            <ng-template pTemplate="caption">
              <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                  <p-button type="button" class="p-button-secondary pr-2" icon="pi pi-upload"
                            [label]="'ATTACHMENTS.ADD' | translate"
                            (click)="openModalUpload(id, 'PROJECTS')"></p-button>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="name">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileName">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.NAME
                    <p-sortIcon field="fileName"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="fileSize">
                  <div class="p-d-flex p-jc-between p-ai-center" translate>
                    COMMON.SIZE
                    <p-sortIcon field="buyer.name"></p-sortIcon>
                  </div>
                </th>
                <th style="width: 6.5rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-attachment>
              <tr class="p-row">
                <td>
                  {{attachment.name}}
                </td>
                <td>
                  <i [ngClass]="attachmentsService.getFileContentType(attachment.fileContentType)" class="mr-3 pi text-primary text-xl"></i>
                  {{attachment.fileName}}
                </td>
                <td>
                  {{Math.floor(attachment.fileSize / 1024)}} kB
                </td>
                <td>
                  <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-download"
                          (click)="downloadAttachment(attachment)"></button>
                  <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                          (click)="deleteAttachment(attachment)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
              </tr>
            </ng-template>
          </p-table>

        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <!--    endregion PRODUCT TABS    -->

  <!--    region  ADD ORDER / OFFER BUTTONS  -->
  <!--<div class="col-12 md:col-12 p-xl-4">
    <div class="grid">
      <div class="col-12 md:col-12 p-lg-6">
        <div class="card widget-feature-box" (click)="openArticleModal(null)">
          <i class="pi pi-plus mr-2" style="font-size: 20px"></i>
          <i class="pi pi-fw pi-id-card" style="font-size: 40px"></i>
          <h2 translate>BUTTON.ARTICLES</h2>
          <span translate>BUTTON.ADD_NEW_ARTICLE</span>
        </div>
      </div>
      &lt;!&ndash;<div class="col-12 md:col-12 p-lg-6">
        <div class="card widget-feature-box" (click)="addOrder()">
          <i class="pi pi-plus mr-2" style="font-size: 20px"></i>
          <i class="pi pi-fw pi-check-square" style="font-size: 40px"></i>
          <h2 translate>BUTTON.ORDERS</h2>
          <span translate>BUTTON.ADD_NEW_ORDER</span>
        </div>
      </div>&ndash;&gt;
    </div>
  </div>-->
  <!--    endregion  ADD ORDER / OFFER BUTTONS    -->
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
