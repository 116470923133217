import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {BaseApi, ListData, StandardFilter} from '../core/api/base-api';
import {Note} from '../models/note.model';
import {Customer} from '../models/customer.model';
import {Project} from '../models/project.model';

export class NotesFilter extends StandardFilter {
  search?: string;
  customerId?: string;
  projectId?: string;
  deleted?: boolean;
}

@Injectable()
export class NotesService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: NotesFilter): Observable<ListData<Note>> {
    return this.list<Note>(`${environment.apiUrl}/notes`, { params: this.params(filter) });
  }

  findById(id: number): Observable<Note> {
    return this.http.get<Note>(`${environment.apiUrl}/notes/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/notes`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/notes/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/notes/${id}`);
  }

  getAllForCustomer(customerId: any): Observable<Note[]> {
    return this.http.get<Note[]>(`${environment.apiUrl}/notes/${customerId}`);
  }

  getAllForProject(projectId: any): Observable<Note[]> {
    return this.http.get<Note[]>(`${environment.apiUrl}/notes/${projectId}`);
  }
}
