import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {AttachmentsService} from '../../../service/attachments.service';

@Component({
  selector: 'app-attachment-full-modal',
  templateUrl: './attachment-full-modal.component.html',
  styleUrls: ['./attachment-full-modal.component.scss']
})
export class AttachmentFullModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  fileName: string;
  id: string;
  entityType: string;
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translate: TranslateService,
    private attachmentsService: AttachmentsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      file: fb.control(null),
      fileName: fb.control(null),
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.id = this.config.data.id;
      this.entityType = this.config.data.entityType;
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  onUploadAttachment(): void {
    // Logic after file upload is completed
    this.messageService.add({
      severity: 'info',
      summary:  this.translate.instant('ATTACHMENTS.UPLOAD.COMPLETED'),
      detail: this.translate.instant('ATTACHMENTS.UPLOAD.FILE'),
    });
    this.ref.close(); // Close the modal window
  }

  onFileSelect(event: any): void {
    const selectedFile = event.files[0]; // Get the selected file

    // Check if a file is selected
    if (selectedFile) {
      const fileName = this.form.get('fileName').value;
      const id = this.id;
      const entityType = this.entityType;

      // Call the uploadAttachment method from AttachmentsService
      this.attachmentsService.uploadAttachment(selectedFile, fileName, id, entityType)
        .subscribe(
          response => {
            this.onUploadAttachment();
          },
          error => {
            console.log(error);
          });
    }
  }
}
