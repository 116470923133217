import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Codelist } from '../../../models/codelist.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CodelistsService } from '../../../service/codelists.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-codelist-colorcc-full-modal',
  templateUrl: './codelist-colorcc-full-modal.component.html',
  styleUrls: ['./codelist-colorcc-full-modal.component.scss']
})
export class CodelistColorccFullModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup;

  filtered: any;

  @Input() codelist: Codelist;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      name: fb.control(null, Validators.required),
      code: fb.control(null, Validators.required),
      description: fb.control(null),
    });
  }
  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.codelist = this.config.data.codelist;
    }
    if (this.codelist && this.codelist.id) {
      this.form.patchValue(this.codelist);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const codelist = {...this.codelist, ...this.form.getRawValue()};

    if (codelist.id) {
      this.codelistsService.update(codelist.id, codelist, 'colorsCc')
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated codelist: ' + codelist.name,
            });
            this.ref.close(codelist);
          },
          error => {
            console.log(error);
          });
    } else {
      this.codelistsService.create(codelist, 'colorsCc')
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created codelist: ' + codelist.name,
            });
            this.ref.close(codelist);
          },
          error => {
            console.log(error);
          });
    }
  }

}
