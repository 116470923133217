<app-floor-plan></app-floor-plan>
<div class="grid mt-3">
  <div *ngFor="let sensor of items$|async" class="col-12 md:col-6 xl:col-4" (click)="viewSensorData(sensor)" style="cursor: pointer;">
    <div class="card no-gutter widget-overview-box widget-overview-box-1">
      <span class="overview-icon">
        <i class="pi pi-wifi"></i>
      </span>
      <span class="overview-title"><span translate>SENSORS.SENSOR</span> {{sensor.sensorId}}</span>
      <div class="grid overview-detail mb-3">
        <div class="col-4 right-separator-line">
          <div class="overview-number">{{sensor.temperature}} °C</div>
          <div class="overview-subtext" translate>SENSORS.TEMPERATURE</div>
        </div>
        <div class="col-4 right-separator-line">
          <div class="overview-number">{{sensor.humidity}} %</div>
          <div class="overview-subtext" translate>SENSORS.HUMIDITY</div>
        </div>
        <div class="col-4">
          <div class="overview-number">{{priceService.round((sensor.battery / 3 * 100),2) > 100 ? 100 : priceService.round((sensor.battery / 3 * 100), 2)}} %</div>
          <span class="overview-subtext" translate>SENSORS.BATTERY</span>
          <span *ngIf="sensor.battery <= 2" class="product-badge status-outofstock ml-3" translate>
            <i class="pi pi-exclamation-triangle"></i>
          </span>
          <span *ngIf="sensor.battery > 2" class="product-badge status-instock ml-3" translate>
            <i class="pi pi-check-square"></i>
          </span>
        </div>
      </div>
      <span class="overview-icon">
        <i class="pi pi-clock"></i>
      </span>
      <span class="overview-title">{{sensor.timestamp|date:'dd MMM yyyy H:mm'}}</span>
    </div>
  </div>
</div>
