import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {BaseApi, StandardFilter} from '../core/api/base-api';
import {Opportunity} from '../models/opportunity.model';

export class OpportunitiesFilter extends StandardFilter {
  search?: string;
  customerId?: string;
  projectId?: string;
  deleted?: boolean;
}

@Injectable()
export class OpportunitiesService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: OpportunitiesFilter): Observable<Opportunity[]> {
    return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities`, { params: this.params(filter) });
  }

  findById(opportunityId: number): Observable<Opportunity> {
    return this.http.get<Opportunity>(`${environment.apiUrl}/opportunities/${opportunityId}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/opportunities`, data);
  }

  update(opportunityId: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/opportunities/${opportunityId}`, data);
  }

  delete(noteId: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/opportunities/${noteId}`);
  }

  getAllForCustomer(customerId: any): Observable<Opportunity[]> {
    return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities/${customerId}`);
  }

  getAllForProduct(productId: any): Observable<Opportunity[]> {
    return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities/${productId}`);
  }
}
