import {Auditable} from '../core/api/common/auditable';
import {Project} from './project.model';
import {Customer} from './customer.model';

export class Note extends Auditable {
  id: number;
  title: string;
  text: string;
  customer?: Customer;
  project?: Project;
  dateCreated: Date;
}
