<dx-vector-map id="vector-map" [projection]="projection">
  <dxo-tooltip
    [enabled]="true"
    [customizeTooltip]="customizeTooltip"
  ></dxo-tooltip>
  <dxi-layer
    [hoverEnabled]="false"
    [dataSource]="buildingData"
    name="building"
  ></dxi-layer>
  <dxi-layer
    color="transparent"
    [dataSource]="roomsData"
    name="rooms"
    [borderWidth]="1"
  >
    <dxo-label [enabled]="true" dataField="name"></dxo-label>
  </dxi-layer>
</dx-vector-map>
