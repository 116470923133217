<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>IMPORTS.ERRORS</h5>
      <p-table #dt [value]="errors" dataKey="id"
               [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0" [globalFilterFields]="[]">
        <ng-template pTemplate="body" let-error>
          <tr class="p-row">
            <td>
              {{error.id}}
            </td>
            <td>
              <span class="customer-badge status-unqualified">{{error.errorMessage}}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
