import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Country, Customer } from '../../../models/customer.model';
import { CustomersService } from '../../../service/customers.service';
import { InetisService } from '../../../service/inetis.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CodelistsService } from '../../../service/codelists.service';
import { emailValidator } from '../../forms/validators/email-validator';

@Component({
  selector: 'app-customer-full-modal',
  templateUrl: './customer-full-modal.component.html',
  styleUrls: ['./customer-full-modal.component.scss']
})
export class CustomerFullModalComponent implements OnInit, OnChanges, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup;

  filtered: any;
  filteredCustomer: any;

  @Input() customer: Customer;
  @Input() dialog: boolean;
  @Output() updatedCustomer: EventEmitter<Customer> = new EventEmitter();

  constructor(
    private customersService: CustomersService,
    private inetisService: InetisService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    private modal: DialogModule,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      customerType: fb.control(null, Validators.required),
      customerId: fb.control(null),
      registrationNumber: fb.control(0),
      name: fb.control(null, Validators.required),
      nameLong: fb.control(null),
      url: fb.control(null),
      email: fb.control(null, [emailValidator]),
      phone: fb.control(null),
      mobile: fb.control(null),
      fax: fb.control(null),
      vat: fb.control(null),
      company: fb.control(false),
      address: fb.group({
        id: fb.control(null),
        street1: fb.control(null, Validators.required),
        street2: fb.control(null),
        city: fb.control(null),
        postal: fb.control(null, Validators.required),
        postalCode: fb.control(null, Validators.required),
        stateOrProvince: fb.control(null),
        country: fb.control(null, Validators.required),
      }),
      /*deliveryAddress: fb.group({
        id: fb.control(null),
        street1: fb.control(null),
        street2: fb.control(null),
        city: fb.control(null),
        postal: fb.control(null),
        postalCode: fb.control(null),
        stateOrProvince: fb.control(null),
        country: fb.control(null),
      }),*/
/*      contacts: fb.group({
        id: fb.control(null),
        customerId: fb.control(null),
        firstName: fb.control(null),
        lastName: fb.control(null),
        phone: fb.control(null),
        mobile: fb.control(null),
        email: fb.control(null),
        comment: fb.control(null),
      }),*/
      deliveryAddress: null,
      contacts: null,
      termsOfPayment: null,
      limit: null
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.form.reset();
    if (Object.keys(this.customer).length !== 0) {
      this.form.patchValue(this.customer);
    } else {
      this.form.get('company').setValue(false);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
    this.customer = {};
  }

  companySwitch(): void {
    if (this.form.get('company').value) {
      this.form.get('vat').enable();
    } else {
      this.form.get('vat').reset();
      this.form.get('vat').disable();
    }
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  filterCustomer(event): void {
    let filteredCustomer: any[] = [];
    const query = event.query;
  // a request to a remote url with the query and return filtered results
    this.inetisService.findByName(query)
      .subscribe(
        data => {
          if (data != null) {
            filteredCustomer = data;
          }
          this.filteredCustomer = filteredCustomer;
        },
        error => {
          console.log(error);
        });
  }

  onSelectFilterCustomers(event: any): void {
    this.form.patchValue(event);
    this.form.get('registrationNumber').setValue(Number(this.form.get('registrationNumber').value));
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const customer = {...this.customer, ...this.form.getRawValue()};

    if (customer.id) {
      this.customersService.update(customer.id, customer)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated customer: ' + customer.name,
            });
            this.updatedCustomer.emit(customer);
            this.customer = {};
          },
          error => {
            console.log(error);
          });
    } else {
      this.customersService.create(customer)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created customer: ' + customer.name,
            });
            this.updatedCustomer.emit(customer);
            this.customer = {};
          },
          error => {
            console.log(error);
          });
    }
    this.dialog = false;
  }
}
