<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>CUSTOMERS.CUSTOMERS</h5>
      <p-table #dt
               [value]="customers"
               [lazy]="true"
               (onLazyLoad)="pageChanged($event)"
               [(selection)]="selectedCustomer"
               dataKey="id"
               [rowHover]="true"
               [rows]="filter.limit"
               [totalRecords]="(count$ | async) || 0"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,15,20,50,200]"
               [loading]="loading"
               [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0"
               [globalFilterFields]="['name']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
              <p-button icon="pi pi-plus" (click)="addCustomerModal($event)"
                        [label]="'CUSTOMERS.CREATE_CUSTOMER' | translate"></p-button>
            </span>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMER.NAME
                <p-sortIcon field="name"></p-sortIcon>
                <p-columnFilter type="text" field="name" display="menu" class="ml-auto"></p-columnFilter>
              </div>
            </th>
            <th pSortableColumn="id">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMERS.CUSTOMER_ID
                <p-sortIcon field="id"></p-sortIcon>
                <p-columnFilter type="text" field="id" display="menu" class="ml-auto"></p-columnFilter>
              </div>
            </th>
            <th pSortableColumn="address.street1">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMER.ADDRESS
                <p-sortIcon field="address.street1"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="address.city">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMER.CITY
                <p-sortIcon field="address.city"></p-sortIcon>
              </div>
            </th>
            <!--            <th pSortableColumn="address.country.name">
                          <div class="p-d-flex p-jc-between p-ai-center" translate>
                            CUSTOMERS.COUNTRY
                            <p-sortIcon field="address.country.name"></p-sortIcon>
                          </div>
                        </th>-->
            <th pSortableColumn="email">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMERS.EMAIL
                <p-sortIcon field="email"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="vat">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMERS.VAT
                <p-sortIcon field="vat"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="revenue">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMERS.REVENUE
                <p-sortIcon field="revenue"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="outstanding">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMERS.OUTSTANDING
                <p-sortIcon field="outstanding"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem;"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr class="p-row">
            <td (click)="viewCustomer(customer.id)" style="cursor: pointer;">
              {{customer.name}}
            </td>
            <td>
              {{customer.id}}
            </td>
            <td>
              {{customer.address?.street1}}
            </td>
            <td>
              {{customer.zip}} {{customer.address?.city}}
            </td>
            <td>
              {{customer.email}}
            </td>
            <td>
              {{customer.vat}}
            </td>
            <td>
            </td>
            <td>
            </td>
            <td>
              <button pButton type=button class="p-button-secondary table-button mr-2" icon="pi pi-user-edit"
                      (click)="editCustomerModal(customer, $event)"></button>
              <button pButton class="p-button-danger table-button" icon="pi pi-trash"
                      (click)="delete(customer)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>CUSTOMERS.NO_CUSTOMERS_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-customer-full-modal [dialog]="customerDialog" *ngIf="customerForEdit"
                         [customer]="customerForEdit" (updatedCustomer)="dialogChanged($event)"
></app-customer-full-modal>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
