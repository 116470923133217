import { Component } from '@angular/core';
import {AppComponent} from './app.component';
import packageJson from '../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

  public version: string = packageJson.version;

  constructor(public app: AppComponent) {}
}
