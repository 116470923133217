import { Alchemist, Category, Color, Gloss, Pantheon, Product, Recipe, Type, Unit } from './product.model';
import { Auditable } from '../core/api/common/auditable';

export class Article extends Auditable {
  id: number;
  name: string;
  nameLong: string;
  alchemist?: Alchemist;
  pantheon?: Pantheon;
  code: number;
  description: string;
  ean: string;
  density: number;
  length: number;
  width: number;
  height: number;
  colorCc?: Color;
  colorNcs?: Color;
  colorRal?: Color;
  gloss?: Gloss;
  type?: Type;
  recipe?: Recipe;
  category?: Category;
  unit?: Unit;
  priceNet?: number;
  priceGross?: number;
  priceTotal?: number;
  priceList?: PriceList;
  discount?: number;
  discountList?: DiscountList;
  quantity: number;
  product: Product;
  volume: number;
  volumeUnit: number;
  mass: number;
  massUnit: number;
  packing: Packing;
  pantheonId: number;
}

export class Packing {
  id:	string;
  name:	string;
  description:	string;
  volume:	number;
  type:	string;
  material:	string;
  color:	string;
  content:	string;
  closing:	string;
  notes:	string;
  length:	number;
  width:	number;
  height:	number;
  weightCan:	number;
  weightCap:	number;
  weightRing:	number;
  weigthGross:	number;
}

export class PriceList {
  id: number;
  price?: number;
}

export class DiscountList {
  id: number;
  discount?: number;
}
