import { Component, OnInit } from '@angular/core';
import {NoteFullModalComponent} from '../../core/modals/note-full-modal/note-full-modal.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OpportunitiesFilter, OpportunitiesService} from '../../service/opportunities.service';
import {Opportunity} from '../../models/opportunity.model';
import {OpportunityFullModalComponent} from '../../core/modals/opportunity-full-modal/opportunity-full-modal.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {

  loading = true;
  ref: DynamicDialogRef;
  opportunities: any[];

  filter: OpportunitiesFilter = new OpportunitiesFilter(0, 10);

  constructor(
    public dialogService: DialogService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private opportunitiesService: OpportunitiesService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.filter = {...this.filter, ...JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')};
    // Check if you come from home -> create = 1 then open modal for create new offer
    this.route.queryParams.subscribe(params => {
      if (params.create === '1') {
        this.openOpportunityModal(params.customer, null);
      }
    });
    this.fetchOpportunities();
  }

  fetchOpportunities(): void {
    this.opportunitiesService.find(this.filter)
      .subscribe(
        data => {
          this.opportunities = data;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  openOpportunityModal(customer: string, opportunity: Opportunity): void {
    this.ref = this.dialogService.open(OpportunityFullModalComponent, {
      header: this.translate.instant('OPPROTUNITIES.OPPORTUNITY'),
      width: '60vw',
      data: {
        customer,
        opportunity
      }
    });

    // tslint:disable-next-line:no-shadowed-variable
    this.ref.onClose.subscribe((opportunity: Opportunity) => {
      if (opportunity) {
        this.fetchOpportunities();
      }
    });
  }

  deleteOpportunity(opportunity: Opportunity): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete opportunity ' + opportunity.id + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.opportunitiesService.delete(opportunity.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('SUCCESS.SUCCESS'),
                detail: this.translate.instant('SUCCESS.DELETED.OPPORTUNITY') + ' ' + opportunity.id,
              });
              this.fetchOpportunities();
            },
            error => {
              console.log(error);
            });
      }
    });
  }
}
