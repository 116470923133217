<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>MENU.OPPORTUNITIES</h5>
      <p-table #dt [value]="opportunities" dataKey="id"
               [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0" [globalFilterFields]="['title', 'dateCreated', 'customer.name']">
          <ng-template pTemplate="caption">
            <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                        <span class="p-input-icon-left">
                          <p-button icon="pi pi-plus" class="p-button-raised p-w-100"
                                    (click)="openOpportunityModal(null, null)"
                                    [label]="'OPPORTUNITIES.CREATE_OPPORTUNITY' | translate"></p-button>
                        </span>
            </div>
          </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem"></th>
            <th pSortableColumn="customer.name">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.CUSTOMER
                <p-sortIcon field="buyer.name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="product">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                COMMON.PRODUCT
                <p-sortIcon field="number"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="value">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                COMMON.VALUE
                <p-sortIcon field="number"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="frequency">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                COMMON.FREQUENCY
                <p-sortIcon field="number"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="status">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                COMMON.STATUS
                <p-sortIcon field="number"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="probability">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                COMMON.PROBABILITY
                <p-sortIcon field="number"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="dateRealization">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OPPORTUNITIES.DATE_REALIZATION
                <p-sortIcon field="dateCreated"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-opportunity let-expanded="expanded">
          <tr class="p-row">
            <td>
              <button type="button" pButton pRipple [pRowToggler]="opportunity" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>
              {{opportunity.customer.name}}
            </td>
            <td>
              {{opportunity.product?.description}}
            </td>
            <td>
              {{opportunity.value}}
            </td>
            <td>
              {{opportunity.frequency?.name}}
            </td>
            <td>
              {{opportunity.status?.name}}
            </td>
            <td>
              {{opportunity.probability?.name}}
            </td>
            <td>
              {{opportunity.periodOfPotentialDeal | date: 'dd.MM.yyyy'}}
            </td>
            <td>
              <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                      (click)="openOpportunityModal(null, opportunity)"></button>
              <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                      (click)="deleteOpportunity(opportunity)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-opportunity>
          <tr>
            <td colspan="5" class="pl-3">
              <span [innerHTML]="opportunity.description"></span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>OPPORTUNITIES.NO_OPPORTUNITIES_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
