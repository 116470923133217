import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import {CustomHttpParamEncoder} from '../util/custom-http-param-encoder';

export const MAX_LIMIT = Math.pow(2, 32) / 2 - 1;

export class FilterUtil {
  static activeExtraFilters(filter: StandardFilter): number {
    return Object.keys(filter)
      .filter(key => key !== 'orderBy' && key !== 'limit' && key !== 'offset')
      .length;
  }

  static hasExtraFilters(filter: StandardFilter): boolean {
    return FilterUtil.activeExtraFilters(filter) > 0;
  }
}

export abstract class StandardFilter {
  orderBy?: string;
  limit ? = 0;
  offset ? = 10;

  constructor(offset?: number, limit?: number, orderBy?: string) {
    this.offset = offset;
    this.limit = limit;
    this.orderBy = orderBy;
  }
}

export class ListData<T> {
  values: T[];
  count: number;

  constructor(values: T[], count: number) {
    this.values = values;
    this.count = count;
  }
}

export class Count {
  value: number;
}

export interface Mapper {
  [key: string]: (value: any, params: HttpParams) => { name: string, value: any }[] | HttpParams;
}

export abstract class BaseApi {

  protected constructor(protected http: HttpClient) {
  }

  protected list<T>(url: string, options?: {
    headers?: HttpHeaders;
    observe?: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<ListData<T>> {
    return this.http.get<Array<T>>(url, Object.assign({observe: 'response'}, options || {})).pipe(
      map((res: HttpResponse<Array<T>>) => new ListData(res.body || [], +res.headers.get('X-Count')))
    );
  }

  protected params(filter: StandardFilter, embed: string[] = [], mapper?: Mapper): HttpParams {
    let params: HttpParams = new HttpParams({encoder: new CustomHttpParamEncoder()});

    Object.keys(filter).forEach(key => {
      if (filter[key] == null || filter[key] === '') {
        return;
      }

      if (mapper && mapper[key]) {
        const mappedParams = mapper[key](filter[key], params);
        if (mappedParams instanceof HttpParams) {
          params = mappedParams;
        } else if (mappedParams && mappedParams.length > 0) {
          mappedParams.forEach(p => params = params.append(p.name, p.value));
        }
        return;
      }

      if (filter[key] instanceof Array) {
        filter[key].forEach((value) => params = params.append(key, value));
      } else if (filter[key].day && filter[key].month && filter[key].year) {
        params = params.append(key, moment(`${filter[key].year}-${filter[key].month}-${filter[key].day}`, 'YYYY-M-D').format('YYYY-MM-DD'));
      } else if (filter[key] instanceof Date) {
        params = params.append(key, moment(filter[key]).toISOString());
      } else {
        params = params.append(key, filter[key]);
      }
    });

    embed.forEach((e: string) => params = params.append('embed', e));

    return params;
  }

  protected jsonToBlob(json: any): Blob {
    return new Blob([JSON.stringify(json)], {type: 'application/json'});
  }
}
