import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';

export class Language {
  name: string;
  flag: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class I18nUtilService implements OnDestroy {

  private language: BehaviorSubject<Language> = new BehaviorSubject(null);
  private languages: BehaviorSubject<Language[]> = new BehaviorSubject([
    {name: 'EN', flag: 'gb', code: 'en'},
    {name: 'HR', flag: 'hr', code: 'hr'},
    {name: 'SL', flag: 'si', code: 'sl'}
  ]);

  language$: Observable<Language | null> = this.language.asObservable();
  languages$: Observable<Language[]> = this.languages.asObservable();

  constructor(private translate: TranslateService) {
  }

  ngOnDestroy(): void {
    this.language.complete();
    this.languages.complete();
  }

  initialize(): void {
    const languages = this.languages.getValue();

    this.translate.addLangs(languages.map(lang => lang.code));
    this.translate.setDefaultLang('en');
    this.translate.use(localStorage.getItem('lang') || this.translate.getBrowserLang() || 'en');
    moment.locale(this.translate.currentLang);

    this.language.next(languages.find(lang => lang.code === this.translate.currentLang));
  }

  select(lang: Language): void {
    this.language.next(lang);
    this.translate.use(lang.code);
    moment.locale(lang.code);
    localStorage.setItem('lang', lang.code);
  }
}
