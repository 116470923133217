<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="fileName" translate>ATTACHMENTS.FILE_NAME</label>
      <input id="fileName" type="text" pInputText formControlName="fileName">
    </div>
  </div>
</form>
<p-footer>
  <p-fileUpload name="file"
                [chooseLabel]="'ATTACHMENTS.ADD' | translate"
                (onSelect)="onFileSelect($event)"
                mode="basic"
                [auto]="true"
                maxFileSize="10000000"
                class="mb-3">
  </p-fileUpload>
</p-footer>
