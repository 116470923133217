import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {BaseApi, StandardFilter} from '../core/api/base-api';
import {Form} from '../models/form.model';

export class FormsFilter extends StandardFilter {
  search?: string;
  deleted?: boolean;
}

@Injectable()
export class FormsService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(): Observable<Form[]> {
    return this.http.get<Form[]>(`${environment.apiUrl}/cq/forms`);
  }

  findByCustomerId(formId: number, customerId: number): Observable<Form> {
    return this.http.get<Form>(`${environment.apiUrl}/cq/forms/${formId}/customers/${customerId}`);
  }

  findByProjectId(formId: number, projectId: number): Observable<Form> {
    return this.http.get<Form>(`${environment.apiUrl}/cq/forms/${formId}/projects/${projectId}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cq/forms`, data);
  }

  update(formId: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cq/forms/${formId}`, data);
  }

  updateForCustomer(customerId: any, formId: number, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cq/forms/${formId}/customers/${customerId}`, data);
  }

  updateForProject(projectId: any, formId: number, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cq/forms/${formId}/projects/${projectId}`, data);
  }

  delete(formId: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/cq/forms/${formId}`);
  }

  findByName(name: any): Observable<Form[]> {
    return this.http.get<Form[]>(`${environment.apiUrl}/cq/forms?name=${name}`);
  }
}
