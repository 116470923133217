<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>MENU.IMPORTS</h5>
      <p-table #dt [value]="imports" dataKey="id"
               [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0" [globalFilterFields]="[]">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
                   <p-fileUpload name="file"
                                 url="{{uploadUrl}}"
                                 chooseLabel="{{'ATTACHMENTS.UPLOAD_PRICE_LIST' | translate}}"
                                 (onUpload)="onUploadAttachment()"
                                 mode="basic"
                                 [auto]="true"
                                 maxFileSize="10000000"
                                 accept=".xlsx,.xls"
                                 class="mb-3">
                   </p-fileUpload>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.ID
                <p-sortIcon field="id"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="fileName">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                IMPORTS.FILENAME
                <p-sortIcon field="fileName"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="type">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                IMPORTS.TYPE
                <p-sortIcon field="type"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="status">
              <div class="p-d-flex p-jc-between p-ai-center text-right" translate>
                IMPORTS.STATUS
                <p-sortIcon field="status"></p-sortIcon>
              </div>
            </th>
            <!--            <th style="width: 6.5rem"></th>-->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-import>
          <tr class="p-row">
            <td [ngStyle]="{'cursor': import.status != COMPLETED ? 'pointer' : 'default'}"
                (click)="import.status != COMPLETED ? viewImport(import.id) : null">
              {{import.id}}
            </td>
            <td>
              {{import.parameters.fileName}}
            </td>
            <td>
              {{import.type}}
            </td>
            <td class="text-right">
              <span class="customer-badge"
                [class.status-qualified]="import.status == COMPLETED"
                [class.status-unqualified]="import.status != COMPLETED">
                {{import.status}}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
