import { Auditable } from '../core/api/common/auditable';

export class Product extends Auditable {
  id: number;
  name: string;
  pantheon?: Pantheon;
  code?: string;
  description?: string;
  density?: number;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  colorCc?: Color;
  colorNcs?: Color;
  colorRal?: Color;
  gloss?: Gloss;
  type?: Type;
  recipe?: Recipe;
  category?: Category;
  unit?: Unit;
  accountingClassification?: string;
}

export class Recipe {
  id?: any;
  materials?: Material[];
}

export class Category {
  id?: any;
  name?: string;
  description?: string;
  rank?: number;
}

export class Type {
  id: any;
  name: string;
  description?: string;
}

export class Unit {
  id: any;
  name: string;
  description?: string;
}

export class Gloss {
  id?: any;
  name?: string;
  description?: string;
}

export class Color {
  id: any;
  name?: string;
  description?: string;
  code: string;
}

export class Material {
  name?: string;
  amount?: string;
}

export class Pantheon {
  id: number;
  name?: string;
  description?: string;
}

export class Alchemist {
  id: number;
  name?: string;
  description?: string;
}
