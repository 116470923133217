import { Injectable } from '@angular/core';
import { AttachmentsService } from './attachments.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class SharedAttachmentService {

  constructor(
    private attachmentsService: AttachmentsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  downloadAttachment(attachment: any) {
    this.attachmentsService.downloadAttachment(attachment.id).subscribe({
      next: (blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = attachment.fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      error: (err) => {
        console.error('Download error:', err);
      }
    });
  }

  deleteAttachment(attachment: any, fetchAttachmentsCallback: () => void) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + attachment.fileName + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully deleted attachment: ' + attachment.fileName,
            });
            fetchAttachmentsCallback();
          },
          error: (err) => {
            console.error('Delete error:', err);
          }
        });
      }
    });
  }
}
